import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from '../../services/message.service';
import { TurnkeyTrackerService } from '../../services/turnkey-tracker.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-modal-header-common',
  templateUrl: './modal-header-common.component.html',
  styleUrls: ['./modal-header-common.component.scss']
})
export class ModalHeaderCommonComponent {
    @Input() title: any;
    
    constructor(){}

    ngOnInit() {
      
    }
}

