import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tips',
  templateUrl: './tips.component.html',
  styleUrls: ['./tips.component.scss']
})
export class TipsComponent implements OnInit {
  @Input() type: any;
  @Input() tipType: any;
  @Output() closetips = new EventEmitter<{}>();

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.closetips.emit(false);
  }

}
