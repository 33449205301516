<div class="dynamicAutocomplete">
    <section class="autocomplete mr-2" *ngIf="showvalue">
        <form [formGroup]="autocompleteFormgroup">
            <mat-form-field appearance="outline">
                <img class="img-fluid" matPrefix src="/assets/images/balancescore/balanceheader/filter.svg" alt="filter"
                    [hidden]="iconhide || false">
                <mat-label>{{inputname | titlecase}}</mat-label>
                <input id="myControl_auto" matInput [matAutocomplete]="auto" type="text" [formControl]="myControl">
                <img class="img-fluid" matSuffix src="/assets/images/balancescore/balanceheader/search.svg" alt="filter"
                    [hidden]="iconhide || false">
                <mat-autocomplete id="displayFn" #auto="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option"
                        (click)="changePortfolio(option)">
                        {{option.display_name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>
    </section>
</div>