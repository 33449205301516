import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }
  login(payload: any) {
    return this.http.post(`api-token-auth/`, payload);
  }
  otplogin(payload: any) {
    return this.http.post(`vendor_otp/verify_otp/`, payload);
  }
  verifyUserToken(token: any) {
    let data ={ "token":token}
    return this.http.post(`api-token-verify/`, data)
  }
  loginSSO(payload: any) {
    return this.http.post(`users/check-user-exists/`, payload);
  }

  forgotPassword(username: any) {
    return this.http.post(`v1/users/get-user-profile/`, username);
  }

  signupuser(payload: any) {
    return this.http.post(`users/register/`,  payload);
  }
  createotp(payload: any) {
    return this.http.post(`users/create-otp/`, payload);
  }
  verifyotp(payload: any) {
    return this.http.post(`users/verify-otp/`, payload);
  }
  createuser(payload: any) {
    return this.http.post(`users/create-user/`, payload);
  }

  changepassword(data: any, token: any) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `JWT ${token}`);
    return this.http.post('api-password-reset-confirm/', JSON.stringify(data), { 'headers': headers })

  }


  getLinkPasswordResetLink(data: any) {
    return this.http.post(`api-password-reset/`, data)
  }
  verifyToken(token: any) {
    return this.http.post(`api-password-reset-verify/`, token)
  }

  getTokenfromSSO(profile: any) {
    let payload = {
      "first_name": profile.givenName,
      "last_name": profile.surname,
      "email": profile.userPrincipalName
    }
    return this.http.post(`users/get-token-by-sso/`, payload);
  }

  triggerOtp(data: any) {
    return this.http.post(`vendor_otp/`, data)
  }
}

