import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { OkrConvergenceService } from 'src/app/shared/services/okr-convergence.service';
import { GetRandomColorPipe } from "../../shared/pipes/get-random-color.pipe";
import { ProgramService } from 'src/app/shared/services/program.service';
import { MatDialog } from '@angular/material/dialog';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { NgxPermissionsService } from 'ngx-permissions';
import { DatePipe } from '@angular/common';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
@Component({
  selector: 'app-toe-master',
  templateUrl: './toe-master.component.html',
  styleUrls: ['./toe-master.component.scss'],
  providers: [GetRandomColorPipe,DatePipe],
  encapsulation: ViewEncapsulation.Emulated
})
export class ToeMasterComponent implements OnInit {
  selectedtoeChildIndex: any = '';
  toeCategoryForm!: FormGroup;
  toeSubCategoryForm!: FormGroup;
  toeForm!: FormGroup;
  metricForm!:FormGroup;

  currentYear: number | any;
  showGoalInput: boolean = true;
  GoalTitle: string | any
  GoalDesc: string | any;
  showSubCategoryInput: boolean[] = new Array().fill(false);
  parentTree: boolean[] = new Array().fill(false);
  childTree: boolean[] = new Array().fill(false);
  toeList: any[] = [];
  mastertoeList: any;
  noDataShow: boolean = false;
  selectedCardIndex: any = '';
  showToeForm: boolean = false;
  filteredOptions: Observable<any[]> | any;
  toeToDeleteObj: any;
  metricsArray:any=[];
  mosTypes=['Need Improvement',"LTB","HTB", 'Binary(Adherence)'];
  submitted=false;
  mosSubmitted=false;
    
  functionCategory: any;
  filterFunctionTracker: any;

  metricID:number = -1;
  jsonString = JSON.stringify;
  teamList: any[] = [];
  selectedCategory: any;
  righttoeList: any;
  toeCategoryFormSubmit: boolean = false;
  toeSubCategoryFormSubmit: boolean = false;
  toeFormSubmit: boolean = false;
  toeCategoryIndexVal: any = 0;
  staticText: any = (textConfiguration as any).default;
  editObject:any = {};

  frequencyOptions: any;
  unitOptions: any;
  selectedFrequencies: any = [];
  filterOptionType: Observable<any[]> | any;
  mosIndex: number=-1;  
  selectedMetricIndex: number =-1;

  mastersArray:any =[];

  constructor(
    private ProgramService: ProgramService,
    private toeConvergenceService: OkrConvergenceService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private randomColor: GetRandomColorPipe,
    private permissionsService: NgxPermissionsService,
    private dialog: MatDialog
  ) { 
  }

  ngOnInit(): void {
    this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem('permission') || '{}').permissions);
    this.createForms();
    this.currentYear = new Date().getFullYear();
    this.getMetricDetails();
    this.getTermMasters();
    this.getFunctions();
    this.gettoeConvergenceList();
  }
  createForms() {
    this.toeCategoryForm = this.formBuilder.group({
      categoryName: ['', Validators.required]
    })

    this.toeSubCategoryForm = this.formBuilder.group({
      subCategoryName: ['', Validators.required]
    })

    this.toeForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      function: ['', [Validators.required]],
      isMandatory:['', [Validators.required]],
      searchMetric:[''],
      fulfilmentBy:['', [Validators.required]],
      metrics:this.formBuilder.array([])
    });
    this.metricForm = this.formBuilder.group({
      metricName: ['', [Validators.required]],
      metricDesc: ['',Validators.required],
      mosType: ['',Validators.required],
      frequency: ['',Validators.required],
      remarks:['']
    });
  }
  displayFnName(data: any) {
    return data && data.name ? data?.name : data?.full_name;
  }
  selected(controlName:string,event: MatAutocompleteSelectedEvent): void{
    let values = this.toeForm.get(controlName)?.value??[];
    let found = values?.find((e:any)=>e.name == event.option.value?.name)
    if(!found)
      values.push(event.option.value);
    this.toeForm.get(controlName)?.setValue(values);
  }
  removeOption(controlName:string,optionName:string){
    let values = this.toeForm.get(controlName)?.value??[];
    values = values.filter((e:any)=> e.name != optionName);
    this.toeForm.get(controlName)?.setValue(values)
  }
  private typeFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions.filter((i: any) => i.name?.toLowerCase().includes(filterValue));
  }

  gettoeConvergenceList() {
    this.spinner.show();
    this.toeConvergenceService.getToEMasters().subscribe((convergenceList: any) => {
      this.spinner.hide();

      this.toeList = convergenceList.records.find((x: any) => x.field_name == "opportunity_contract_terms")?.field_values;
      
      // below code for default first card selection in left pane 
      this.mastertoeList = convergenceList?.records?.[0]?.field_values?.[0]?.['term_sub_category']?.[0];
      if (this.mastertoeList)
        this.mastertoeList['selectedCardBgColor'] = this.randomColor?.transform?.(0);;

      if (this.mastertoeList?.term?.length > 0) this.noDataShow = true;
      
      this.righttoeList = this.selectedtoeChildIndex ? this.mastertoeList?.term?.[this.selectedtoeChildIndex] : {};
      this.selectedCategory = this.toeList?.[0]?.category_uuid;
      this.parentTree[0]=true;
    },err=>this.spinner.hide())
  }

  showCenterPaneData(evt: Event, subParentData: any, cardIndex: number, selectedtoeCategory: any) {
    if (evt) evt.stopPropagation();
    this.selectedtoeChildIndex = '';
    this.toeForm.reset();
    this.selectedCategory = selectedtoeCategory;    
    subParentData['selectedCardBgColor'] = this.randomColor.transform(cardIndex);
    this.mastertoeList = subParentData;
    if (this.mastertoeList?.term) this.noDataShow = true;
    /*else*/ this.selectedCardIndex = cardIndex;
    console.log(this.mastertoeList);

  }
  onClickAddToE(){
    this.childTree = new Array().fill(false);
    this.metricsArray=[];
    this.toeForm.reset();
    this.selectedtoeChildIndex = -1;
    if(this.mastertoeList?.sub_category_uuid) 
      this.showToeForm=!this.showToeForm
    else
      this.showToeForm=false
  }
  showRightPaneData(item: any, index: any) {  
    this.toeForm.reset();    
    this.showToeForm=false;
    let value = this.childTree[index]; 
    this.childTree = new Array().fill(false);
    this.childTree[index] = !value;
    this.righttoeList = item;
    this.selectedtoeChildIndex = index;
    let functionId = this.functionCategory?.find((e:any)=> e.id === item?.function);
    this.toeForm.get("title")?.setValue(item?.title);
    this.toeForm.get("description")?.setValue(item?.description);
    this.toeForm.get("function")?.setValue(functionId);
    this.toeForm.get("isMandatory")?.setValue(item?.is_mandatory);
    this.toeForm.get("fulfilmentBy")?.setValue(item?.fulfilment_by);
    this.metricsArray = item?.metrics_details;  
    this.mastersArray?.applicability?.forEach((element:any) => {
      let values = item.applicability_details.filter((o:any)=> o.parent === element?.id);
      this.toeForm.get(element?.name)?.setValue(values??[]);
    });
    item?.consequences_details?.forEach((element:any) => {
      this.toeForm.get(element?.consequences_details?.name)?.setValue(true);
      this.toeForm.get(element?.consequences_details?.name+"_description")?.setValue(element?.description);
    });
    
    item?.compliance_details?.forEach((element:any) => {
      this.toeForm.get(element?.name)?.setValue(true);
    });
    
    item?.evidence_details?.forEach((element:any) => {
      this.toeForm.get(element?.name)?.setValue(true);
    });
    console.log(this.toeForm,this.metricsArray);
    
  }

  deletetoeChild() {
    this.spinner.show();
    this.ProgramService.deleteCustomKeyResutls(this.toeToDeleteObj.sub_category_uuid, this.toeToDeleteObj.category_uuid).subscribe((res: any) => {
      this.toastrService.success("ToE Deleted Successfully");
      let toeChildIndex = this.mastertoeList.term.findIndex((findIndex: any) => findIndex.sub_category_uuid === this.toeToDeleteObj.sub_category_uuid);
      this.mastertoeList.term.splice(toeChildIndex, 1);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.error('Could Not Able To Delete ToE');
      throw error;
    })
  }
  deleteSubCategory(){
    this.spinner.show();
    this.ProgramService.deleteCustomKeyResutls(this.toeToDeleteObj.sub_category_uuid, this.toeToDeleteObj.category_uuid).subscribe((res: any) => {
      this.toastrService.success("Sub category Deleted Successfully");
      let toeChildIndex = this.mastertoeList.term.findIndex((findIndex: any) => findIndex.sub_category_uuid === this.toeToDeleteObj.sub_category_uuid);
      this.mastertoeList.term.splice(toeChildIndex, 1);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastrService.error('Could Not Able To Delete Sub category');
      throw error;
    })
  }
  
  createtoeCategory() {
    this.spinner.show();
    if (this.toeCategoryForm.status === 'VALID') {
      this.toeCategoryFormSubmit = false;
      this.spinner.show();
      let payload = {
        "is_active": true,
        "name": this.toeCategoryForm.get("categoryName")?.value,
        "display_name": this.toeCategoryForm.get("categoryName")?.value,
        "is_custom": true
      }
      this.toeConvergenceService.createUpdateToECategory(payload).subscribe((response: any) => {      
        this.spinner.hide();
        if (response?.status === 200 || 201) {
          this.toastrService.success("Category Created SuccessFully");
          this.toeCategoryForm.reset();
          this.toeList.unshift({
            category_display_name: response?.display_name,
            category_name: response?.name,
            category_uuid: response?.uuid,
            delete: true,
            is_custom: true
          });
        } else {
          this.toastrService.success("Error with creating toe Goal");
        }
      });
    } else {
      this.toeCategoryFormSubmit = true;
      this.spinner.hide();
      this.toastrService.error("please Fill all Required Fields");
    }
  }

  createtoeSubCategory() {
    this.spinner.show();
    if (this.toeSubCategoryForm.status === 'VALID') {
      this.toeSubCategoryFormSubmit = false;
        
      let payload = {
        "is_active": true,
        "name": this.toeSubCategoryForm.get("subCategoryName")?.value,
        "display_name": this.toeSubCategoryForm.get("subCategoryName")?.value,
        "is_custom": true,
        "term_category": this.toeList[this.toeCategoryIndexVal]?.category_uuid
      }
      this.toeConvergenceService.createUpdateToESubCategory(payload).subscribe((response: any) => {      
        this.spinner.hide();
        if(response?.status  === 200 || 201) {
          this.toastrService.success("Toe SubCategory created successfully");
          this.spinner.hide();
          this.toeSubCategoryForm.reset();
          this.parentTree = new Array().fill(false);
          this.parentTree[this.toeCategoryIndexVal] = true;
          if(!this.toeList[this.toeCategoryIndexVal]['term_sub_category']){
            this.toeList[this.toeCategoryIndexVal]['term_sub_category'] = []
          }
          this.toeList[this.toeCategoryIndexVal]['term_sub_category'].unshift({
            sub_category_display_name: response?.display_name,
            sub_category_name: response?.name,
            sub_category_uuid: response?.uuid,
            delete: true,
            is_custom: true,
            category_uuid:this.toeList[this.toeCategoryIndexVal]?.category_uuid
          });          
        } else {
          this.toastrService.error("Error with creating toe SubCategory");
          this.spinner.hide();
        }
      });
    } else {
      this.toeSubCategoryFormSubmit = true;
      this.spinner.hide();
      this.toastrService.error("please Fill all Required Fields");
    }
  }
  createToE(isUpdate:boolean=false){
    this.toeFormSubmit = true;
    if (this.toeForm.valid) {    
      this.spinner.show();
      let metricIds = this.metricsArray.map((e:any)=>e.id);
      let payload:any = {
         "title": this.toeForm.get("title")?.value,
         "description": this.toeForm.get("description")?.value,
         "term_category": this.mastertoeList?.category_uuid,
         "term_sub_category": this.mastertoeList?.sub_category_uuid,
         "is_custom": true,
         "function": this.toeForm.get("function")?.value?.id,
         "is_mandatory":  this.toeForm.get("isMandatory")?.value,
         "fulfilment_by": this.toeForm.get("fulfilmentBy")?.value,
         "metrics": metricIds,
      }
      
      let applicabilityArr:any = [];
      this.mastersArray?.applicability?.forEach((element:any) => {
        if(this.toeForm.get(element?.name)?.value && this.toeForm.get(element?.name)?.value?.length>0){
          let ids = this.toeForm.get(element?.name)?.value.map((e:any)=>e.id);
          applicabilityArr = applicabilityArr.concat(ids);
        }
      });
      payload['applicability'] = applicabilityArr;
      
      let consequencesArr:any = [];
      this.mastersArray?.consequences?.forEach((element:any) => {
        if(this.toeForm.get(element?.name)?.value)
          consequencesArr.push({consequence:element?.id,description:this.toeForm.get(element?.name+'_description')?.value});
      });
      payload['consequences'] = consequencesArr;
      
      let complianceArr:any = [];
      this.mastersArray?.compliance?.forEach((element:any) => {
        if(this.toeForm.get(element?.name)?.value)
          complianceArr.push(element?.id);
      });
      payload['compliance'] = complianceArr;
      
      let evidenceArr:any = [];
      this.mastersArray?.evidence?.forEach((element:any) => {
        if(this.toeForm.get(element?.name)?.value )
        evidenceArr.push(element?.id);
      });
      payload['evidence'] = evidenceArr;

      if(isUpdate){
        payload['uuid'] = this.righttoeList?.uuid;
      }
      this.toeConvergenceService.createUpdateToE(payload).subscribe((response: any) => {      
        this.spinner.hide();
        this.toeFormSubmit =false;
        if (response?.status === 200 || 201) {
          this.toastrService.success(!isUpdate? "ToE created successfully":"ToE updated successfully");
          if(!isUpdate){
            this.toeForm.reset();
            this.showToeForm = false;            
          }
          this.gettoeConvergenceList();
        } else {
          this.toastrService.success(!isUpdate?"Error with creating ToE":"Error with updating ToE");
        }
      });
    } else {
      this.spinner.hide();
      this.toastrService.error("please fill all the required fields");
    }
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  onSelectMosType(openFun:any){
    if(this.metricForm.value.mosType == 'Need Improvement'){
      this.metricForm.addControl('from',new FormControl('',Validators.required));
      this.metricForm.addControl('to',new FormControl('',Validators.required));
      this.metricForm.removeControl('minValue');
      this.metricForm.removeControl('maxValue');
      this.metricForm.removeControl('yesNo');
    }else if(this.metricForm.value.mosType == 'LTB'){
      this.metricForm.addControl('minValue',new FormControl('',Validators.required));
      this.metricForm.removeControl('from');
      this.metricForm.removeControl('to');
      this.metricForm.removeControl('maxValue');
      this.metricForm.removeControl('yesNo');
    }else if(this.metricForm.value.mosType == 'HTB'){
      this.metricForm.addControl('maxValue',new FormControl('',Validators.required));
      this.metricForm.removeControl('from');
      this.metricForm.removeControl('to');
      this.metricForm.removeControl('minValue');
      this.metricForm.removeControl('yesNo');
    }else{
      this.metricForm.addControl('yesNo',new FormControl('',Validators.required));
      this.metricForm.removeControl('from');
      this.metricForm.removeControl('to');
      this.metricForm.removeControl('minValue');
      this.metricForm.removeControl('maxValue');
      this.metricForm.removeControl('unit');
      this.metricForm.removeControl('onTrack');
      this.metricForm.removeControl('offTrack');
      this.metricForm.removeControl('atRisk');
    }

    if(this.metricForm.value.mosType == 'Need Improvement' || this.metricForm.value.mosType == 'LTB'|| this.metricForm.value.mosType == 'HTB'){      
      this.metricForm.addControl('unit',new FormControl('',Validators.required));
      this.metricForm.addControl('onTrack',new FormControl('',Validators.required));
      this.metricForm.addControl('offTrack',new FormControl('',Validators.required));
      this.metricForm.addControl('atRisk',new FormControl('',Validators.required));
    }
    this.metricForm.updateValueAndValidity();
    if(openFun){      
      openFun();
    }
  }

  addMetric() {
    this.mosSubmitted = true;
    if(this.metricForm.invalid){
      this.toastrService.error("Please fill mandatory fields");
      return;
    }
    this.spinner.show();
    let payload:any={ 
      "name": this.metricForm.value.metricName, 
      "description": this.metricForm.value.metricDesc, 
      "mos_type_name": this.metricForm.value.mosType, 
      "frequency": this.metricForm.value.frequency,  
      "unit_name": this.metricForm.value.unit, 
      "from_value": this.metricForm.value.from,  
      "to_value": this.metricForm.value.to, 
      "on_track_value": this.metricForm.value.onTrack, 
      "off_track_value": this.metricForm.value.offTrack,  
      "at_risk_value": this.metricForm.value.atRisk,  
      "remarks": this.metricForm.value.remarks,    
      "min_value": this.metricForm.value.minValue,
      "max_value": this.metricForm.value.maxValue,
      "binary_value": this.metricForm.value.yesNo,
    }
    if(this.metricID>-1 ){
      payload['master_metrics'] = this.metricID;
    }
    if(this.editObject?.id){
      this.toeConvergenceService.updateMetricDetails(this.editObject?.id,payload).subscribe((res)=>{
        this.spinner.hide();
        let index = this.metricsArray.findIndex((e:any)=>e.id ===this.editObject?.id);
        if(index>-1)
          this.metricsArray[index] = res;
        this.closeDialog();
        this.metricForm.reset();
      }) 
    }else{
      this.toeConvergenceService.saveMetricDetails(payload).subscribe((res)=>{
        this.spinner.hide();    
        this.metricsArray.push(res);
        this.closeDialog();
        this.metricForm.reset();
      })
    }  
  }
  onClickEdit(item:any,modal:any){
    this.submitted=false;
    this.metricForm.get("mosType")?.setValue(item?.mos_type_name);
    let fun:any = ()=>{
      this.editObject=item;
      this.openMoSDialog(modal,item);
    }
    this.onSelectMosType(fun);

  }
  deleteMetric(index:number){
    this.metricsArray.splice(index,1);
  }
  deleteMeasureofSuccess() {
    this.spinner.show();
    this.toeConvergenceService.deleteMeasureofSuccess(this.toeToDeleteObj['measure_of_success'][this.mosIndex].id).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.mastertoeList.term[this.selectedtoeChildIndex]['measure_of_success'].splice(this.mosIndex, 1);
        this.toastrService.success("Data deleted successfully")
      }
    })
  }
  opentoeDeleteDialog(model: any, data: any) {
    this.toeToDeleteObj = data;
    this.dialog.open(model);
  }
  onClickLeftPane(data: any, toeCategoryIndex: any) {
    this.selectedtoeChildIndex = -1;
    this.selectedCardIndex = 0;
    this.selectedCategory = data;
    let value = this.parentTree[toeCategoryIndex]
    this.parentTree = new Array().fill(false);
    this.parentTree[toeCategoryIndex] = !value;
    this.childTree = new Array().fill(false);    
    if (this.parentTree[toeCategoryIndex]) this.toeCategoryIndexVal = toeCategoryIndex
    this.toeCategoryIndexVal = toeCategoryIndex;
    this.mastertoeList = data?.term_sub_category ? data?.term_sub_category?.[0] : {}
    this.mastertoeList['selectedCardBgColor'] = this.randomColor?.transform?.(0);
    if (this.mastertoeList?.term) this.noDataShow = true;
  }
  openMoSDialog(model: any, data: any,isExist?:boolean) {
    this.mosSubmitted=false;
    if(isExist){
      this.metricID = data?.id;
    }else{
      this.metricID = -1;
    }
    if(this.metricForm.value.mosType == 'Need Improvement'){
      this.metricForm.patchValue({
        metricName: data?.name,
        metricDesc: data?.description,
        mosType: data?.mos_type_name,
        frequency: data?.frequency,
        unit: data?.unit_name,
        from: data?.from_value,
        to: data?.to_value,
        onTrack: data?.on_track_value,
        atRisk: data?.at_risk_value,
        offTrack: data?.off_track_value,
        remarks: data?.remarks
      });
    }else if(this.metricForm.value.mosType == 'LTB'){      
      this.metricForm.patchValue({
        metricName: data?.name,
        metricDesc: data?.description,
        mosType: data?.mos_type_name,
        frequency: data?.frequency,
        unit: data?.unit_name,
        minValue: data?.min_value,
        onTrack: data?.on_track_value,
        atRisk: data?.at_risk_value,
        offTrack: data?.off_track_value,
        remarks: data?.remarks
      });
    }else if(this.metricForm.value.mosType == 'HTB'){            
      this.metricForm.patchValue({
        metricName: data?.name,
        metricDesc: data?.description,
        mosType: data?.mos_type_name,
        frequency: data?.frequency,
        unit: data?.unit_name,
        maxValue: data?.max_value,
        onTrack: data?.on_track_value,
        atRisk: data?.at_risk_value,
        offTrack: data?.off_track_value,
        remarks: data?.remarks
      });
    }else{                
      this.metricForm.patchValue({
        metricName: data?.name,
        metricDesc: data?.description,
        mosType: data?.mos_type_name,
        frequency: data?.frequency,
        yesNo: data?.binary_value?'Yes':'No',
        remarks: data?.remarks
      });
    }    
    this.dialog.open(model);
  }
  onSelectionChange(evt:any,modal:any){
    let data:any= evt?.option?.value;
    this.editObject={};
    this.openMoSDialog(modal,data,true);
  }  
  getMetricDetails(searchKey?:string){
    this.spinner.show();
    this.toeConvergenceService.getAllMetrics(searchKey).subscribe((response: any) => {      
      this.spinner.hide()
      if(response){
        this.filteredOptions = response;         
        this.filterOptionType = this.toeForm.get('searchMetric')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this.typeFilter(name) : this.filteredOptions?.slice())
        );
        let element:any = document.querySelector('[formcontrolname=searchMetric]');
        element?.focus(); 
      }
    });
  }
  getTermMasters(){
    this.spinner.show();   
    this.toeConvergenceService.getTermMasters().subscribe((response: any) => {      
      this.spinner.hide()
      if(response){
        this.mastersArray = response;    
        this.mastersArray?.applicability?.forEach((element:any) => {
          this.toeForm.addControl(element?.name,new FormControl(''));
        });
        this.mastersArray?.consequences?.forEach((element:any) => {
          this.toeForm.addControl(element?.name,new FormControl(''));
          this.toeForm.addControl(element?.name+"_description",new FormControl(''));
        });
        this.mastersArray?.compliance?.forEach((element:any) => {
          this.toeForm.addControl(element?.name,new FormControl(''));
        });
        this.mastersArray?.evidence?.forEach((element:any) => {
          this.toeForm.addControl(element?.name,new FormControl(''));
        });
        this.unitOptions = this.mastersArray?.measure_of_success_unit;
        this.frequencyOptions = this.mastersArray?.measure_of_success_frequency;    
      }
    });
  }
  getFunctions() {
    this.toeConvergenceService.getFunctionsList().subscribe((response: any) => {
      this.functionCategory = response;
      this.filterFunctionTracker = this.toeForm.get('function')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.display_name),
          map((name: any) => name ? this._filterFunctionTracker(name) : this.functionCategory.slice())
        );
    });
  }
  
  private _filterFunctionTracker(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.functionCategory.filter((e: any) => e?.display_name?.toLowerCase().includes(filterValue));
  }
  onSelectCheckbox(controlName:string, evt:any ){
    if(evt.checked){
      this.toeForm.get(controlName+"_description")?.addValidators(Validators.required);
      this.toeForm.get(controlName+"_description")?.updateValueAndValidity();
    }else{
      this.toeForm.get(controlName+"_description")?.clearValidators();
      this.toeForm.get(controlName+"_description")?.updateValueAndValidity();
    }
  }

  getTagObject(id: any){
    return {
      entity_id: id,
      entity_type: "toe"
    }
  }
}