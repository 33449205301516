import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root'
})
export class DealStructureService {
  filterObj: any;
  
  constructor(private http: HttpClient, private filterService: FilterService) {
    this.filterService.filterSubject.subscribe((res: any) => {
      this.filterObj = res;
   })
   }

   getDealDetails(id:any){
    return this.http.get(`/deal/deal/${id}`);
  }
  addNewDeal(data:any){
    return this.http.post('/deal/deal/',data);
  }
  deleteDealStructureById(id:any){
    return this.http.delete(`/opportunity/${id}/`).toPromise()
  }
  archiveDealStructureById(item:any){
    return this.http.patch(`deal/deal/${item}/`, {"is_active":false}).toPromise();
  }
  getArchiveList(portfolio: any, subportfolio: any, program: any){
    return this.http.get(`deal/deal/get-deal-list/?portfolio=${portfolio}&sub_portfolio=${subportfolio}&program=${program}`).toPromise();
  }
  getProgramList(){
    return this.http.get(`delivery/get-complete-program-list/`);
  }

  getDealToeData(dealId:any){
    return this.http.get(`/deal/deal-term/?deal_id=${dealId}`);
  }
  selectDealToe(data:any){
    return this.http.post('/deal/deal-term/',data);
  }
  updateDeal(id:any,payload:any){
    return this.http.patch(`deal/deal/${id}/`,payload);
  }
  getDealKrData(dealId: any){
    return this.http.get(`deal/deal-goals/${dealId}/dealgoals/`);
  }
  selectDealGoals(payload: any){
    return this.http.post(`deal/deal-goals/select-dealgoals/`, payload);
  }
  getDealScopeData(dealId:any){
    return this.http.get(`deal/deal-scope/get-scope-deal/${dealId}`);
  }
  updateDealScope(dealId:any,payload:any){
    return this.http.put(`deal/deal-scope/update_deal_scope/${dealId}/`,payload);
  }
  getDealStructureList(payload: any, queryParams: any = ''){
    return this.http.get(`delivery/get-opportunity-list/?portfolio=${payload?.parent?.id}&program=${payload.id}` + queryParams).toPromise();
  }
  getPortfolioPrograms(payload: any, queryParams: any = ''){
    if(this.filterObj?.subportfolio?.array?.length > 0){
      let body: any = { portfolio: this.filterObj.subportfolio};
      return this.http.post(`delivery/get-filtered-list/?` + queryParams, body).toPromise();
    }
    return this.http.get(`delivery/get-opportunity-programs/?portfolio=${payload.id}` + queryParams).toPromise();
  }

  
  getVendor(){
    return this.http.get(`/delivery/get-complete-vendor-list/`);
  }
  postAwardDeal(payload:any){
    return this.http.post('deal/deal-vendor/',payload)
  }
  getDealAwardVendor(dealId:any){
    return this.http.get(`deal/deal-vendor/?deal_id=${dealId}`);
  }
  downloadAwardFile(id:any){
    return this.http.get(`deal/deal-vendor/download_deal_document/${id}/`,{ responseType: 'blob' })
  }
}
