<span *ngIf="module == 'list'">
    <div class="col-md-12" *ngIf="items?.length">
        <mat-card id="padding" *ngFor="let i of items | filter:searchBoxText"
            [ngClass]="{'recommendBlue': !i?.custom, 'customRed': i?.custom || i?.custom_parameter }"
            class="row border-light mt-2 list-border d-flex p-2">
            <div class="col-md-10 pt-1">
                {{ i.display_name || i.custom_parameter_details?.display_name}}
            </div>
            <div class="col-md-2 pt-1 p-0 d-flex justify-content-center">
                <div *ngIf="i.custom && i.delete == true && !disableEdit">
                    <mat-icon matSuffix class="fs-5 text-danger cursor-pointer me-2"
                        *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                        (click)="callPopUp(i, removeCategory)">
                        delete_outlined
                    </mat-icon>
                </div>
                <div *ngIf="((i.custom && i.delete == false) || i?.custom_parameter) && !disableEdit">
                    <mat-icon class="fs-5 text-muted me-2" *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                        matTooltip="Custom parameter consumed, can't be removed"  matTooltipClass="example-tooltip">delete_outlined</mat-icon>
                </div>
                <div *ngIf="!disableEdit">
                    <mat-icon class=" cursor-pointer float-end fs-5 icon-color" (click)=addColumn(i)>
                        add</mat-icon>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="text-center" *ngIf="!items?.length && search">
        <mat-card class="list-border">
            <p class="my-2">{{contents.searchText}}</p>
        </mat-card>
    </div>
    <div class="text-center" *ngIf="!items?.length && !search">
        <p class="my-2">{{staticText.common.no_records_found}}</p>
    </div>
</span>

<span *ngIf="module == 'parameterList'">
    <div class="col-md-12" *ngIf="items?.length">
        <mat-card id="padding" *ngFor="let i of items | filter:searchBoxText"
            [ngClass]="{'recommendBlue': !i?.is_custom, 'customRed': i?.is_custom == true }"
            class="row border-light mt-2 list-border d-flex p-1">
            <div class="col-md-10">
                {{ i.display_name }}
            </div>
            <div class="col-md-2 pt-1 p-0 d-flex">
                <div *ngIf="i.custom && i.delete == true && !disableEdit">
                    <mat-icon matSuffix class="fs-5 text-danger cursor-pointer me-2"
                        *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                        (click)="callPopUp(i, removeCategory)">
                        delete_outlined
                    </mat-icon>
                </div>
                <div *ngIf="i.custom && i.delete == false && !disableEdit">
                    <mat-icon class="fs-5 text-muted me-2" *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                        matTooltip="Custom parameter consumed, can't be removed"  matTooltipClass="example-tooltip">delete_outlined</mat-icon>
                </div>
                <div *ngIf="!disableEdit">
                    <mat-icon class=" cursor-pointer float-end fs-5 icon-color" (click)=addColumn(i)>
                        add</mat-icon>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="text-center" *ngIf="!items?.length && search">
        <mat-card class="list-border">
            <p class="my-2">{{contents.searchText}}</p>
        </mat-card>
    </div>
    <div class="text-center" *ngIf="!items?.length && !search">
        <!-- <p class="my-2"> {{staticText.common.no_records_found}}</p> -->
    </div>
</span>

<span *ngIf="module == 'timeList'">
    <div class="col-md-12" *ngIf="items?.length">
        <mat-card *ngFor="let i of items | filter:searchBoxText" class="row border-light mt-2 d-flex list-border p-1"
            [ngClass]="{'recommendBlue': !i?.is_custom, 'customRed': i?.is_custom }">
            <div class="col-md-10">
                {{ i.display_name }}
            </div>
            <div class="col-md-2 pt-1 p-0 d-flex">
                <div *ngIf="i.is_custom && i.delete == true && !disableEdit">
                    <mat-icon matSuffix class="fs-5 text-danger cursor-pointer"
                        *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                        (click)="callPopUp(i, removeCategory)">
                        delete_outlined
                    </mat-icon>
                </div>
                <div *ngIf="i.is_custom && i.delete == false && !disableEdit">
                    <mat-icon class="fs-5 text-muted" *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                        matTooltip="Custom parameter consumed, can't be removed"  matTooltipClass="example-tooltip">delete_outlined</mat-icon>
                </div>
                <div *ngIf="!disableEdit">
                    <mat-icon class="title-color cursor-pointer float-end fs-5" (click)=addColumn(i)>
                        add</mat-icon>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="text-center" *ngIf="!items?.length && search">
        <p>{{contents.searchText}}</p>
    </div>
    <div class="text-center" *ngIf="!items?.length && !search">
        <p> {{staticText.common.no_records_found}}</p>
    </div>
</span>


<ng-template #removeCategory>
    <div class="modal-dialog m-0">
        <div class="modal-content">
            <app-modal-header-common [title]="staticText?.modal_popup?.confirmation_title"> </app-modal-header-common>
            
            <div class="modal-body">
                <p class="fs-18 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.del_msg}}</p>
                <p>{{staticText?.modal_popup?.cant_retrieve}}</p>
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="text-blue" (click)=closeDialog()>
                            {{staticText?.buttons?.cancel_btn}}</button>
                    </div>
                    <div class="col-md-6" *ngIf="!disableEdit">
                        <button mat-button mat-dialog-close class="text-white bg-red"
                            (click)=closeColumn()>{{staticText?.buttons?.delete_btn}}</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #removeFunction>
    <div class="modal-dialog m-0">
        <div class="modal-content">
            <app-modal-header-common [title]="staticText?.modal_popup?.confirmation_title"> </app-modal-header-common>
            
            <div class="modal-body">
                <p class="fs-18 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.del_msg}}</p>
                <p>{{staticText?.modal_popup?.cant_retrieve}}</p>
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="text-blue" (click)=closeDialog()>
                            {{staticText?.buttons?.cancel_btn}}</button>
                    </div>
                    <div class="col-md-6" *ngIf="!disableEdit">
                        <button mat-button mat-dialog-close class="text-white bg-red"
                            (click)=deleteFunction()>{{staticText?.buttons?.delete_btn}}</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<span *ngIf="module == 'function'">
    <div class="col-md-12" *ngIf="items?.length">
        <mat-card id="padding" *ngFor="let i of items | filter:searchBoxText"
            class="row border-light list-border d-flex mt-2 p-2"
            [ngClass]="{'recommendBlue': !i?.is_custom, 'customRed': i?.is_custom }">
            <div class="col-md-10 pt-1">
                {{ i.display_name }}
            </div>
            <div class="col-md-2 pt-1 p-0 d-flex">
                <div *ngIf="i.is_custom && i.delete == true && !disableEdit">
                    <mat-icon matSuffix class="fs-5 text-danger cursor-pointer"
                        *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                        (click)="callFunctionPopUp(i, removeFunction)">
                        delete_outlined
                    </mat-icon>
                </div>
                <div *ngIf="i.is_custom && i.delete == false && !disableEdit">
                    <mat-icon class="fs-5 text-muted" *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                        matTooltip="Custom function consumed, can't be removed"  matTooltipClass="example-tooltip">delete_outlined</mat-icon>
                </div>
                <div *ngIf="!disableEdit">
                    <mat-icon class="cursor-pointer float-end fs-5 icon-color " (click)=addColumn(i)>
                        add</mat-icon>
                </div>
            </div>
        </mat-card>
    </div>
    <div class="text-center" *ngIf="!items?.length && search">
        <p class="my-2">{{contents.searchText}}</p>
    </div>
    <div class="text-center" *ngIf="!items?.length && !search">
        <p class="my-2"> {{staticText.common.no_records_found}}</p>
    </div>
</span>

<span *ngIf="module == 'team'">
    <div class="col-md-12 p-0" *ngIf="items?.length">
        <mat-list>
            <mat-list-item *ngFor="let i of items | filter:searchBoxText; let j =index;"
                class="row w-100 m-0 border my-2 rounded teamList">
                <div class="col-md-2 ps-0">
                    <span class="name" [ngStyle]="{'backgroundColor': j|getRandomColor}">
                        {{getShortName(i?.name)}}</span>
                </div>
                <div class="col-md-8">
                    <div class="">
                        <p class="m-0 fw-bold fs-16"
                            style="color: #232459;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 10.4rem;">
                            {{ i.name }}</p>
                        <p class="m-0 fs-14 text-truncate" style="overflow-x: hidden; color: #7A828B;"
                            matTooltip="{{i.mail}}"  matTooltipClass="example-tooltip">
                            {{ i.mail }}
                        </p>
                    </div>
                    <div class="div-border"></div>
                    <div>
                        <p class="my-2 fs-14 "
                            style="color: #232459;text-transform: capitalize;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;width: 11.6rem;"
                            *ngIf="i.designation">
                            <span class="bg-light py-1"  matTooltip="{{i.designation}}"  matTooltipClass="example-tooltip">
                                {{ i.designation }}
                            </span>
                        </p>
                    </div>
                    <!----------------------------------------REMOVED TEMPORARILY BASED ON RAHAMATH SUGGESTIONS -------------------------->

                    <!-- <p class="my-2 text-uppercase fs-small fw-bold text-white w-auto" *ngIf="i.department">
                        <span class="bg-grey py-1 px-2">
                            {{ i.department }}
                        </span>
                    </p> -->
                </div>
                <div class="col-md-1 mx-1 mb-5 pb-2 px-5">
                    <mat-icon *ngIf="!disableEdit" class="cursor-pointer float-end fs-5 icon-color" (click)=levelSelected.emit(i)>
                        add</mat-icon>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="text-center" *ngIf="!items?.length && search">
        <p class="my-2"> {{staticText.common.no_records_found}}</p>
    </div>
    <div class="text-center" *ngIf="!items?.length && !search">
        <p class="my-2"> {{staticText.common.no_records_found}}</p>
    </div>
</span>

<span *ngIf="module == 'vendor'">
    <div class="col-md-12">
        <mat-list>
            <mat-list-item *ngFor="let i of items| filter:searchBoxText;let j = index;"
                class="row w-100 m-0 border my-2 rounded teamList">
                <div class="col-md-2 mb-5 ps-0">
                    <span class="name" [ngStyle]="{'backgroundColor': j|getRandomColor}"
                        >{{getShortName(i.name)}}</span><!--[ngClass]="{'titlecase-6':  i.name.includes('H'), 'titlecase-7': i.name.includes('Y'), 'titlecase-3': i.name.includes('D'), 'titlecase-5': i.name.includes('A') }"-->
                </div>
                <div class="col-md-9 pt-2 pr-0">
                    <p class="m-0 fw-500 text-truncate fs-16" style="max-width:290px;" matTooltip="{{i.name}}"  matTooltipClass="example-tooltip">{{
                        i.name }}</p>
                    <p class="text-muted text-truncate">
                        {{ i.team }}
                    </p>
                    <div class="row">
                        <div class="col-md-2 d-flex">
                            <mat-icon class="text-warning cursor-pointer fs-5">
                                star</mat-icon>
                            <p class="m-0 fs-medium">{{i.rating}}</p>
                        </div>
                        <div class="col-md-6 d-flex pe-0">
                            <img src="./assets/images/projects-assignment.svg"
                                class="cursor-pointer project-img" matTooltip="Projects">
                            <p class="m-0 fs-small text-muted" 
                            >{{i.project_count}}</p>
                        </div>
                        <div class="col-md-4 d-flex">
                            <mat-icon class="text-secondary cursor-pointer fs-5">
                                person</mat-icon>
                            <p class="m-0 fs-small secondary">Profile</p>
                        </div>

                    </div>
                </div>
                <div class="col-md-1 mx-1 mb-5 pb-2 float-end">
                    <mat-icon *ngIf="!disableEdit" class="cursor-pointer float-end fs-5 icon-color" (click)=levelSelected.emit(i)>
                        add</mat-icon>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="text-center" *ngIf="!items?.length && search">
        <p class="my-2"> {{staticText.common.no_records_found}}</p>
    </div>
    <div class="text-center" *ngIf="!items?.length && !search">
        <p class="my-2">{{staticText.common.no_records_found}}</p>
    </div>
</span>

<span *ngIf="module == 'keyList'">
    <div *ngIf="localArray?.length">
        <app-tree
            *ngFor="let tree of localArray | treefilter:filterListKey:filterListKeyValue | filter:searchBoxText; index as treeindex;"
            [treeclass]="'pt-2 pb-1'" [treearray]="tree" [treecardtype]="'simpletree'" [treeindex]="treeindex"
            [parentTagName]="TreecardTagname" [subParentTagname]="TreecardParentTagname" [addicon]="true"
            (addkeyresult)="addColumn($event)" (deletekeyresult)="callPopUp($event,removeCategory)"
            [deleteicon]="customkeydeleteicon" [filterTreeList]="filterList" [filterTreeListKey]="filterListKey"
            [filterTreeListKeyValue]="filterListKeyValue">
        </app-tree>
    </div>

    <div class="text-center" *ngIf="!localArray?.length && search">
        <p class="my-2">{{contents.searchText}}</p>
    </div>

    <div class="text-center" *ngIf="!localArray?.length && !search">
        <p class="my-2"> {{staticText.common.no_records_found}}</p>
    </div>
</span>

<span *ngIf="module == 'scopeList'">
    <div class="row scope-list">
        <div class="col-md-12 pe-1" id="scopeList" *ngIf="items?.length">
            <mat-card class="row border-light list-border d-flex w-100 my-1 mx-0 px-1 py-0 mb-2"
                *ngFor="let i of items | filter:searchBoxText"
                [ngClass]="{'recommendBlue': !i?.is_custom, 'customRed': i?.is_custom }">
                <div class="col-md-9 pt-1 pe-0 pl-1">
                    <p class="mb-1 text-truncate" matTooltip="{{i.scope_title || i.title}}"  matTooltipClass="example-tooltip">
                        {{ i.scope_title || i.title}}</p>
                    <p class="mb-1 text-muted text-truncate line fs-14" matTooltip="{{i.scope_description || i.description}}">
                        {{i.scope_description || i.description}} </p>
                </div>
                <div class="col-md-3  pt-2 p-0 d-flex justify-content-center">
                    <div *ngIf="i.is_custom && i.delete == true && !disableEdit">
                        <mat-icon matSuffix class="fs-5 text-danger cursor-pointer"
                            *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                            (click)="callPopUp(i, removeCategory)">
                            delete_outlined
                        </mat-icon>
                    </div>
                    <div *ngIf="i.is_custom && i.delete == false && !disableEdit">
                        <mat-icon class="fs-5 text-muted" *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                            matTooltip="Custom parameter consumed, can't be removed"  matTooltipClass="example-tooltip">delete_outlined</mat-icon>
                    </div>
                    <mat-icon *ngIf="!disableEdit" class="title-color cursor-pointer float-end fs-5" (click)=addColumn(i)>
                        add</mat-icon>
                    <!-- <mat-icon (click)="onEdit.emit(i)">edit</mat-icon> -->
                    <img class="edit-icon" *ngIf="!disableEdit" src="/assets/Icons/table-card/edit.svg" (click)="onEdit.emit(i)"/>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="text-center" *ngIf="!items?.length && search">
        <p>{{contents.searchText}}</p>
    </div>
    <div class="text-center" *ngIf="!items?.length && !search">
        <p> {{staticText.common.no_records_found}}</p>
    </div>
</span>

<span *ngIf="module == 'scopeParameterList'">
    <div class="col-md-12 p-0 pl-1" *ngIf="items?.length">

        <mat-card id="padding" *ngFor="let i of items | filter:searchBoxText"
            class="row  mt-2 scopelists-border d-flex w-100 my-2 p-0 mx-0"
            [ngClass]="{'recommendBlue': !i?.is_custom, 'customRed': i?.is_custom }">
            <div class="col-md-9 py-1">
                {{ i.name }}
            </div>
            <div class="col-md-2  pt-1 p-0 d-flex">
                <div *ngIf="i.is_custom && i.delete == true && !disableEdit">
                    <mat-icon matSuffix class="fs-5 text-danger cursor-pointer"
                        *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                        (click)="callPopUp(i, removeCategory)">
                        delete_outlined
                    </mat-icon>
                </div>
                <div *ngIf="i.is_custom && i.delete == false && !disableEdit">
                    <mat-icon class="fs-5 text-muted" *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                        matTooltip="Custom parameter consumed, can't be removed"  matTooltipClass="example-tooltip">delete_outlined</mat-icon>
                </div>
                <mat-icon *ngIf="!disableEdit" class="title-color cursor-pointer float-end fs-5" (click)=addColumn(i)>
                    add</mat-icon>
            </div>
        </mat-card>

    </div>
    <div class="text-center" *ngIf="!items?.length && search">
        <p class="my-2">{{contents.searchText}}</p>
    </div>
    <div class="text-center" *ngIf="!items?.length && !search">
        <p class="my-2"> {{staticText.common.no_records_found}}</p>
    </div>
</span>

<span *ngIf="module == 'categoryList'">
    <div class="col-md-12 pe-0" *ngIf="items?.length">

        <mat-card id="padding" *ngFor="let i of items | filter:searchBoxText"
            class="row scopelists-border d-flex w-100 my-2 mx-0 customRed px-2 py-1">
            <div class="col-md-10 pt-1">
                {{ i.name || i.display_name}}
            </div>
            <div class="col-md-2  pt-1 p-0 d-flex">
                <div *ngIf="i.is_custom && i.delete == true && !disableEdit">
                    <mat-icon matSuffix class="fs-5 text-danger cursor-pointer"
                        *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                        (click)="callPopUp(i, removeCategory)">
                        delete_outlined
                    </mat-icon>
                </div>
                <div *ngIf="i.is_custom && i.delete == false && !disableEdit">
                    <mat-icon class="fs-5 text-muted" *ngxPermissionsOnly="['opportunity.delete_customparameter']"
                        matTooltip="Custom parameter consumed, can't be removed"  matTooltipClass="example-tooltip">delete_outlined</mat-icon>
                </div>
                <mat-icon *ngIf="!disableEdit" class="title-color cursor-pointer float-end fs-5" (click)=addColumn(i)>
                    add</mat-icon>
            </div>
        </mat-card>

    </div>
    <div class="text-center" *ngIf="!items?.length && search">
        <p class="my-2">{{contents.searchText}}</p>
    </div>
    <div class="text-center" *ngIf="!items?.length && !search">
        <p class="my-2"> {{staticText.common.no_records_found}}</p>
    </div>
</span>

<span *ngIf="module == 'fileList'">
    <div class="col-md-12" *ngIf="items?.length">
        <mat-list>
            <mat-list-item id="padding" *ngFor="let i of items" class="row w-100 border rounded m-0 mb-3">
                <div class="col-md-12 px-3">
                    <div class="row">


                        <div class="col-md-1 me-2 ps-0">
                            <img *ngIf="i.file === i.file'.doc'" src="./assets/images/docs.png"
                                height="40">
                            <!-- <img *ngIf="i.file == i.file'.docx' || " src="./assets/images/docs.png" height="40"> -->
                            <img *ngIf="i.file === i.file'.pdf'" src="./assets/images/pdf.png" height="40">
                            <mat-icon *ngIf="i.file != i.file'.pdf'" mat-list-icon
                                class="material-icons my-auto fs-1 text-danger">description</mat-icon>
                            <mat-icon *ngIf="i.file !=  i.file'.doc'" mat-list-icon
                                class="material-icons my-auto fs-1 text-danger">description</mat-icon>
                            <mat-icon *ngIf="i.file !=  i.file'.docx'" mat-list-icon
                                class="material-icons my-auto fs-1 text-danger">description</mat-icon>
                            <!-- && i.doc !== 'doc' && i.doc !== 'docx' -->
                        </div>



                        <div class="col-md-10">
                            <div class="fw-bold">{{ i.file }}</div>
                            <div> {{ i.size | number : '1.2-2' }} MB </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-end" *ngIf=" !disableEdit">
                            <mat-icon matSuffix class="fs-4 text-danger cursor-pointer me-3"
                                (click)="callPopUp(i, removeCategory)">
                                delete_outlined
                            </mat-icon>
                            <mat-icon class="text-secondary cursor-pointer fs-4 secondary" (click)=addColumn(i)>
                                get_app</mat-icon>
                        </div>
                    </div>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
    <div class="text-center" *ngIf="!items?.length && search">
        <p class="my-2">{{contents.searchText}}</p>
    </div>
    <div class="text-center" *ngIf="!items?.length && !search">
        <p class="my-2"> {{staticText.common.no_records_found}}</p>
    </div>
</span>

<span *ngIf="module == 'critiList'">
    <div id="scopeList" *ngIf="items?.length">
        <mat-card *ngFor="let i of items" class="mt-2 my-2 mx-0"
            [ngClass]="{'borderViolet': i.title == 'Non-Negoitable' , 'borderGreen': i.title == 'Mandatory' , 'borderPink': i.title == 'Nice to have' , 'borderRed': i.title == 'Need to have'}">
            <div class="row">
                <div class="col-md-11">
                    <p class="fw-bold text-black">{{ i.title }}</p>

                </div>
                <div class="row p-0 ms-1">
                    <div class="col-md-12">
                        <p class="fs-16 text-dark" matTooltip="{{i.description}}"  matTooltipClass="example-tooltip">
                            {{i.description}}</p>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</span>

<span *ngIf="module == 'critiRange'">
    <div class="col-md-12 p-0" id="scopeList" *ngIf="items?.length">
        <mat-list>
            <mat-list-item *ngFor="let i of items" class="row my-3 ms-0"
                [ngClass]="{'borderViolet': i.title == 'Non-Negoitable' , 'borderGreen': i.title == 'Mandatory' , 'borderPink': i.title == 'Nice to have' , 'borderRed': i.title == 'Need to have'}">
                <div class="col-md-12"
                    [ngClass]="{'rangeViolet': i.title == 'Non-Negoitable' , 'rangeGreen': i.title == 'Mandatory' , 'rangePink': i.title == 'Nice to have' , 'rangeRed': i.title == 'Need to have'}">
                    <p class="my-2  fw-bold text-black">{{ i.title }}</p>
                    <p class="my-2 fs-16 text-dark" matTooltip="{{i.description}}"  matTooltipClass="example-tooltip">
                        {{i.description}}</p>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</span>

<span *ngIf="module == 'weightList'">
    <div class="col-md-12 p-0" id="scopeList" *ngIf="items?.length">
        <mat-list>
            <mat-list-item *ngFor="let i of items" class="row my-3 ms-0"
                [ngClass]="{'borderblue': i.title == '80-100%' , 'borderyellow': i.title == '30-80%' , 'borderred': i.title == '0-30%'}">
                <div class="col-md-12">
                    <p class="my-2 fw-bold text-black"
                        [ngClass]="{'blue': i.title == '80-100%' , 'yellow': i.title == '30-80%' , 'red': i.title == '0-30%'}">
                        {{ i.title }}</p>
                    <p class="my-2 fs-16 text-dark" matTooltip="{{i.description}}"  matTooltipClass="example-tooltip">
                        {{i.description}}</p>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</span>

<span *ngIf="module == 'termsList'">
    <div class="col-md-12 p-1" *ngIf="termsArray?.length">
        <app-tree
            *ngFor='let tree of termsArray | treefilter:filterListKey:filterListKeyValue | filter:searchBoxText;index as treeindex'
            [treearray]="tree" [treeclass]="'py-1'" [treeindex]="treeindex" [treecardtype]="'formtree'"
            [deleteicon]="customkeydeleteicon" [parentTagName]="TreecardTagname"
            [subParentTagname]="TreecardParentTagname" [formTagName]="TreecardToeFormTagname" [addicon]="true"
            (addkeyresult)="addToeMeasurement($event)" (deletekeyresult)="callPopUp($event,removeCategory)">
        </app-tree>
    </div>
    <div class="text-center" *ngIf="!termsArray?.length && search">
        <p class="my-2">{{contents.searchText}}</p>
    </div>
    <div class="text-center" *ngIf="!termsArray?.length && !search">
        <p class="my-2"> {{staticText.common.no_records_found}}</p>
    </div>
</span>

<span *ngIf="module == 'accordionCard'">
    <div class="col-md-12 py-4">
        <input type="text" matInput fullWidth status="basic" [(ngModel)]="searchText" (input)="onInputChange($event)"
            placeholder="Search categories here...">
        <mat-icon>Search</mat-icon>
        <div class="pt-3">
            <button matButton>{{staticText.delivery.okr_log.add_custom}}</button>
        </div>
    </div>
    <div *ngFor="let i of items">
        <div class="col-md-12 py-2">
            <button *ngIf="showData != i" matButton fullWidth class="d-flex justify-content-between"
                (click)="showData = i"><span>{{i.name}}</span><span>
                    <mat-icon icon="plus-outline" pack="eva" style="font-size: 16px"></mat-icon>
                </span></button>
            <div *ngIf="showData == i">
                <mat-card class="w-100" style="background-color: #EEF3F9; margin-bottom: 0.875rem">
                    <div class="row">
                        <div class="d-flex justify-content-between">
                            <span class="card-title">{{i.name}}</span><span>
                                <mat-icon icon="minus-outline" pack="eva" style="font-size: 16px" class="cursor-pointer"
                                    (click)="showData = {}"></mat-icon>
                            </span>
                        </div>
                        <div class="col-md-12 float-end">
                            <p class="pt-1">{{i.discriptions}}</p>
                        </div>
                    </div>
                </mat-card>
                <div *ngFor="let j of i.data">
                    <mat-card class="w-100" style="margin-bottom: 0.875rem">
                        <div class="row">
                            <div class="d-flex justify-content-between">
                                <span class="card-title">{{j.name}}</span><span>
                                    <p class="mb-0 text-secondary float-end cursor-pointer" *ngIf="!disableEdit"  (click)=addColumn(j)>{{staticText.buttons.add_btn}}
                                    </p>
                                </span>
                            </div>
                            <div class="col-md-12 float-end">
                                <p class="pt-1">{{j.discription}}</p>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="text-center" *ngIf="!items?.length">
            <p class="my-2">{{staticText.delivery.okr_log.list_content}}</p>
        </div>
    </div>
    
    <div class="text-center" *ngIf="!items?.length">
        <p class="my-2"> {{staticText.common.no_records_found}}</p>
    </div>
</span>
<span *ngIf="module == 'userCard'">
    <div class="col-md-12 py-4">
        <mat-form-field>
            <input type="text" matInput shape="semi-round" fullWidth status="basic" [(ngModel)]="searchText"
                (input)="onInputChange($event)" placeholder="Search team members here...">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <div *ngFor="let i of items">
        <div class="col-md-12 py-2">
            <mat-card class="w-100">
                <div class="row">
                    <div class="col-md-3">
                        <!-- <mat-card-header>
    <mat-icon class="cursor-pointer" [name]="i.name">account_circle</mat-icon>
    </mat-card-header> -->
                    </div>
                    <div class="col-md-8">
                        <h6>{{i.name}}</h6>
                        <p>{{i.email}}</p>
                        <div *ngFor="let des of i.designation">
                            {{des}}
                        </div>
                    </div>
                    <div class="col-md-1">
                        <p class="mb-0 text-secondary float-end cursor-pointer" *ngIf="!disableEdit" (click)=addColumn(i)>{{staticText.buttons.add_btn}}
                        </p>
                    </div>

                </div>
            </mat-card>
        </div>
    </div>
</span>
<span *ngIf="module == 'help'">
    <div class="row">
        <div class="col-md-12 col-lg-12 ps-3 pe-0">
            <p class="help-border helpleft htopborder p-3 mb-0 border-bottom"><span class="ps-4"><img
                        src="./assets/images/question.svg" style="position: relative;
                right: 17px;
                bottom: 4px;"><span class="help-align">{{staticText.help_content.title}}</span></span></p>
        </div>
        <div class="col-md-12 col-lg-12 ps-3 pe-0">
            <mat-card class="corners2 b-corner card-width helpGreen ps-0">
                <p class="primary fw-500 heading-size px-3">{{staticText.opportunity.title}}</p>
                <p class="p-font px-3">
                    {{staticText.help_content.opp_help}}
                </p>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-lg-12 py-3 ps-3 pe-0">
            <mat-card class="rcorners2 card-width helpOrange">
                <p class="primary fw-500 heading-size">{{staticText.opportunity.setup}}</p>
                <p class="p-font">
                    {{staticText.help_content.setup_help}}
                </p>
            </mat-card>
        </div>
        <div class="col-md-12 col-lg-12 py-3 ps-3 pe-0 pt-0">
            <mat-card class="rcorners2 card-width helpPink">
                <p class="primary fw-500 heading-size">{{staticText.help_content.help_title1}}</p>
                <p class="p-font">
                    {{staticText.help_content.customization_help}}
                </p>
            </mat-card>
        </div>
    </div> 
</span>

<span *ngIf="module == 'profileHelp'">
    <div class="row">
        <div class="col-md-12 col-lg-12 ps-3 pe-0">
            <p class="help-border helpleft h-topborder p-3 mb-0 border-bottom"><span class="ps-4"><img
                        src="./assets/images/question.svg" class="img-align"><span
                        class="help-align">{{staticText.help_content.title}}</span></span></p>
        </div>
        <div class="col-md-12 col-lg-12 ps-3 pe-0">
            <mat-card class="corners2 b-corner card-width helpGreen">
                <p class="primary fw-500 heading-size">{{staticText.opportunity.title}}</p>
                <p class="p-font">
                    {{staticText.help_content.profile_help}}
                </p>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-lg-12 mt-3 ps-3 pe-0">
            <mat-card class="rcorners2 card-width helpOrange">
                <p class="primary fw-500 heading-size">{{staticText.rfx.setup.profile}}</p>
                <p class="p-font">
                    {{staticText.help_content.profile_help1}}
                </p>
            </mat-card>
        </div>
        <div class="col-md-12 col-lg-12 mt-3 ps-3 pe-0 pt-0">
            <mat-card class="rcorners2 card-width helpPink">
                <p class="primary fw-500 heading-size">{{staticText.help_content.help_title2}}</p>
                <p class="p-font">
                    {{staticText.help_content.profile_help2}}
                </p>
            </mat-card>
        </div>
    </div>
</span>

<span *ngIf="module == 'keyResultHelp'">
    <div class="row">
        <div class="col-md-12 col-lg-12 ps-3 pe-0">
            <p class="help-border helpleft h-topborder p-3 mb-0 border-bottom"><span class="ps-4"><img
                        src="./assets/images/question.svg" class="img-align"><span
                        class="help-align">{{staticText.help_content.title}}</span></span></p>
        </div>
        <div class="col-md-12 col-lg-12 pe-0 ps-3">
            <mat-card class="corners2 b-corner card-width helpGreen">
                <p class="primary fw-500 heading-size">{{staticText.opportunity.title}}</p>
                <p class="p-font">
                    {{staticText.help_content.kr_help}}
                </p>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-lg-12 ps-3 pe-0 mt-3">
            <mat-card class="rcorners2 card-width helpOrange">
                <p class="primary fw-500 heading-size">{{staticText.okr_heatmap.krs}}</p>
                <p class="p-font">
                    {{staticText.help_content.kr_help1}}
                </p>
            </mat-card>
        </div>
        <div class="col-md-12 col-lg-12 ps-3 pe-0 pt-0 mt-3">
            <mat-card class="rcorners2 card-width helpPink">
                <p class="primary fw-500 heading-size">{{staticText.help_content.help_title3}}</p>
                <p class="p-font">
                    {{staticText.help_content.kr_help2}}
                </p>
            </mat-card>
        </div>
    </div>
</span>

<span *ngIf="module == 'teamHelp'">    
    <div class="row">
        <div class="col-md-12 col-lg-12 ps-3 pe-0">
        <p class="help-border helpleft h-topborder p-3 mb-0 border-bottom"><span class="ps-4"><img
                    src="./assets/images/question.svg" class="img-align"><span
                    class="help-align">{{staticText.help_content.title}}</span></span></p>
        </div>
        <div class="col-md-12 col-lg-12 ps-3 pe-0">
            <mat-card class="corners2 b-corner card-width helpGreen">
                <p class="primary fw-500 heading-size">{{staticText.opportunity.team}}</p>
                <p class="p-font">
                    {{staticText.help_content.team_help}}
                </p>
            </mat-card>
        </div>
    </div>
</span>

<span *ngIf="module == 'vendorHelp'">
    <div class="row">
        <div class="col-md-12 col-lg-12 ps-3 pe-0">
            <p class="help-border helpleft h-topborder p-3 mb-0 border-bottom"><span class="ps-4"><img
                        src="./assets/images/question.svg" class="img-align"><span
                        class="help-align">{{staticText.help_content.title}}</span></span></p>
        </div>
        <div class="col-md-12 col-lg-12 p-0 ps-3">
            <mat-card class="corners2 b-corner card-width helpGreen">
                <p class="primary fw-500 heading-size">{{staticText.rfx.setup.gdp_fullform}}</p>
                <p>
                    {{staticText.help_content.vendor_help}}
                </p>
            </mat-card>
        </div>
    </div>
</span>

<span *ngIf="module == 'timeLineHelp'">
    <div class="row">
        <div class="col-md-12 col-lg-12 p-0 ps-3">
            <p class="help-border helpleft h-topborder p-3 mb-0 border-bottom"><span class="ps-4"><img
                        src="./assets/images/question.svg" class="img-align"><span
                        class="help-align">{{staticText.help_content.title}}</span></span></p>
        </div>
        <div class="col-md-12 col-lg-12 p-0 ps-3">
            <mat-card class="corners2 b-corner helpGreen" >
                <p class="primary fw-500 heading-size">{{staticText.opportunity.title}}</p>
                <p class="p-font">
                    {{staticText.help_content.timeline_help}}
                    </p>
            </mat-card>
        </div>
        
    <div class="col-md-12 col-lg-12 mt-3 ps-3 pe-0">
        <mat-card class="rcorners2 card-width helpOrange">
            <p class="primary fw-500 heading-size">{{staticText.help_content.help_title4}}</p>
            <p class="p-font">
                {{staticText.help_content.timeline_help1}}</p>

        </mat-card>
    </div>
    </div>
</span>

<span *ngIf="module == 'termsHelp'">
    <div class="row">
        <div class="col-md-12 col-lg-12 ps-3">
            <p class="help-border helpleft h-topborder p-3 mb-0 border-bottom"><span class="ps-4"><img
                        src="./assets/images/question.svg" class="img-align"><span
                        class="help-align">{{staticText.help_content.title}}</span></span></p>
        </div>
        <div class="col-md-12 col-lg-12 ps-3">
            <mat-card class="corners2 b-corner card-width helpGreen">
                <p class="primary fw-500 heading-size">{{staticText.help_content.contract_terms}}</p>
                <p class="p-font">
                    {{staticText.help_content.cterms_help}}
                    </p>
            </mat-card>
        </div>
    </div>
    <div class="col-md-12 ps-0 mt-3">
        <mat-card class="rcorners2 cards-width helpOrange">
            <p class="primary fw-500 heading-size">{{staticText.help_content.kpi_sla}}</p>
            <p class="p-font">
                {{staticText.help_content.kpi_help}}
                </p>
            <p class="p-font">
                {{staticText.help_content.kpi_help1}}</p>
        </mat-card>
    </div>
</span>

<!-- Balance score card sidenav -->
<span *ngIf="module == 'balancescore'" class="mainbalanceScore container">
    <section class="balanceScorecard">
        <div class="d-flex flex-column">
            <div class="balanceScoreheader text-uppercase">
                {{staticText.help_content.balance_sc}}
            </div>
            <div class="p-2 list-content" *ngFor="let item of sample">
                <mat-card class="balancescoreMatcard">
                    <mat-card-content>
                        <p>{{item.para}}</p>
                    </mat-card-content>
                    <mat-card-actions class="text-right">
                        <button mat-raised-button
                            style="background-color: #07b797; color: #ffffff;">{{item.btn}}</button>
                    </mat-card-actions>
                </mat-card>
                <mat-divider class="mt-3"></mat-divider>
            </div>
        </div>
    </section>
</span>
