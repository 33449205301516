import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) { }

  getAllNotifications(status: any) {
    return this.http.get(`notifications/filtered-list/?status=${status}`);
  }

  getAllReadNotifications(id: any, section: any) {
    return this.http.post(`notifications/${id}/mark-as-read/`, { "filters": { "status": 'unread', "section": `${section}` } }, { observe: 'response' });
  }

  // Home page notificaitons APIs

  getNotificationsByPage(pageKey: any) {
    return this.http.get(`notifications/filtered-list/?status=unread&section=${pageKey}`);
  }

  getChatBotQueryList(queryName:any){
    return this.http.get(`https://dev.airo.kairhos.com/server/api/cache/?query=${queryName}`)
  }

  getJWTtoken(){
    return this.http.get(`browseai/get_buster_jwt/`)
  }
}
