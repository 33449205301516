import { ViewEncapsulation } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class SelectComponent implements OnInit {

  module: any;
  items: any;
  formData: any
  submitted: any;
  @Input() formGroupName: any;
  form: FormGroup | any;
  @Output() closeItem = new EventEmitter<{}>();
  
  @Input()
  set type(data: any) {
    this.module = data
  }
  @Input()
  set list(data: any) {
    this.items = data;
  }
  @Input()
  set from(data: FormGroup) {
    this.formData = data;
  }

  @Input()
  set submit(data: any) {
    this.submitted = data
  }

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    if (this.formGroupName) {
      this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    }

  }
  get f() {
    return this.formData.controls;
  }
  close(item: any) {
    this.closeItem.emit(item)
  }

  dataSelect(event: any) {
    this.form.setValue(event.value); 
  }

  }
