<ngx-datatable class="material" [rows]="data" [columns]="columns" [loadingIndicator]="false" [limit]="limit"
    [offset]="offsets" [count]="counts" [externalPaging]="true" (page)="paginates($event)"
    [externalSorting]="true" [sorts]="[{prop: 'name', dir: 'asc'}]" (sort)="sorts($event)"
    [columnMode]="ColumnMode.force" [headerHeight]="headerHeight" rowHeight="auto" #dataTable>
</ngx-datatable>

<ng-template #createdTmpl let-row="row" let-value="value">
    {{value | date :'longDate'}}
</ng-template>
<ng-template #hdrTpl let-column="column"> {{ column.name }}</ng-template>
