import { AfterViewInit, ElementRef, Inject, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as html2canvas from 'html2canvas';
import { MessageService } from './shared/services/message.service';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ParadeCreateModalDialogComponent } from './pages/parade/parade-create-modal/parade-create-modal';
import { ParadeExistingModalDialogComponent } from './pages/parade/parade-existing-modal/parade-existing-modal';

@Component({
  selector: 'add-file',
  templateUrl: './add-file-modal.html',
})
export class AddFileModalComponent implements AfterViewInit {
  filelabel: any;
  fileForm: FormGroup | any;

  constructor(
      public dialogRef: MatDialogRef<AddFileModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
    private toastrService: ToastrService, private fb: FormBuilder) {}

  ngOnInit(){
    this.fileForm = new FormGroup({
      label: new FormControl('', [Validators.required])
    })
  }

  ngAfterViewInit(){
    this.fileForm = new FormGroup({
      label: new FormControl('', [Validators.required])
    })
  }

  saveTemplate(){
    this.dialogRef.close(this.fileForm.value.label);
  }

  cancel(){
    this.dialogRef.close(false)
  }
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AppComponent implements OnInit{
  @ViewChild('screenshotContainer', { static: false }) screenshotContainer: ElementRef;
  private selectionBox: HTMLElement;
  currentCaptureStatus: boolean = false;
  paradeImgArray: any=[];
  iframeEmbedded: boolean = false;

  leftX: number;
  topY: number;

  title = 'kairhos-front-end';
  windowScrolled = false;
  constructor(private router: Router,private spinner:NgxSpinnerService, private renderer: Renderer2,
    private messageService: MessageService, private toastrService: ToastrService, private matDialog: MatDialog,
    private activatedRoute: ActivatedRoute) {
      this.activatedRoute.queryParams
      .subscribe((params: any) => {
        if(params?.embedded == 'true'){
            this.messageService.setIframeData(true);
            this.iframeEmbedded = true;
        }
      }
    );
    

    this.router.events.subscribe((e :any) => {
       this.navigationInterceptor(e);
     })
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: any): void {
    if (event instanceof NavigationStart) {
    //  this.spinner.show()
    }
    if (event instanceof NavigationEnd) {
      this.spinner.hide()
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.spinner.hide()
    }
    if (event instanceof NavigationError) {
      this.spinner.hide()
    }
  }
  ngOnInit(): void {
    this.messageService.getIframeData().subscribe((res: any) => {
      this.iframeEmbedded = typeof res != 'object' ? true : false;
    })

    window.addEventListener('scroll', () => {
      this.windowScrolled = window.pageYOffset !== 0;
    });

    this.messageService.getCaptureStatus().subscribe((res: any) => {
      this.currentCaptureStatus = typeof res == 'object' ? false : res;
    })
  }
  scrollToTop(): void {
    window.scrollTo(0, 0);
  }
  
  // @HostListener("window:beforeunload", ["$event"]) 
  // unloadHandler(event: Event) {
  //   localStorage.setItem('href_data', window.location.href);
  // }

  capture(){
    // this.paradeImgArray = [];
    this.toastrService.info('Parade mode started')
    this.currentCaptureStatus = !this.currentCaptureStatus;
    this.messageService.setCaptureStatus(this.currentCaptureStatus);
  }

  removeCapture(){
    this.currentCaptureStatus = !this.currentCaptureStatus;
    this.messageService.setCaptureStatus(this.currentCaptureStatus);
  }
 

  startSelection(event: MouseEvent) {
    if(this.currentCaptureStatus == true){
      this.selectionBox = this.renderer.createElement('div');
      this.renderer.setStyle(this.selectionBox, 'position', 'absolute');
      this.renderer.setStyle(this.selectionBox, 'border', '1px solid #007bff');
      // this.renderer.setStyle(this.selectionBox, 'background-color', 'rgba(0, 123, 255, 0.2)');
      this.renderer.setStyle(this.selectionBox, 'z-index', '99999999999');
      this.renderer.setStyle(this.selectionBox, 'left', event.clientX + 'px');
      this.renderer.setStyle(this.selectionBox, 'top', event.clientY + 'px');
      this.renderer.appendChild(this.screenshotContainer.nativeElement, this.selectionBox);

      this.leftX = event.clientX;
      this.topY = event.clientY;
  
      this.renderer.listen(document, 'mousemove', this.adjustSelectionBox);
      this.renderer.listen(document, 'mouseup', this.finishSelection);
    }
  }

  adjustSelectionBox = (event: MouseEvent) => {
    const left = Math.min(event.clientX, this.selectionBox.getBoundingClientRect().left);
    const top = Math.min(event.clientY, this.selectionBox.getBoundingClientRect().top);
    const width = Math.abs(event.clientX - this.selectionBox.getBoundingClientRect().left);
    const height = Math.abs(event.clientY - this.selectionBox.getBoundingClientRect().top);

    this.renderer.setStyle(this.selectionBox, 'left', this.leftX + 'px');
    this.renderer.setStyle(this.selectionBox, 'top', this.topY + 'px');
    this.renderer.setStyle(this.selectionBox, 'width', width + 'px');
    this.renderer.setStyle(this.selectionBox, 'height', height + 'px');
  }

  finishSelection = () => {
    this.selectionBox.style.border = "none"
    // this.renderer.removeAttribute(this.selectionBox, 'style');
    this.renderer.removeChild(this.screenshotContainer.nativeElement, this.selectionBox);

    const html2canvasInstance: any = html2canvas;

    if(this.selectionBox.offsetWidth > 50 &&  this.selectionBox.offsetHeight > 50){
      html2canvasInstance(this.screenshotContainer.nativeElement, {
        x: this.selectionBox.getBoundingClientRect().left,
        y: (this.selectionBox.getBoundingClientRect().top - 55) < 0 ? 0 : this.selectionBox.getBoundingClientRect().top - 55,
        width: this.selectionBox.offsetWidth,
        height: this.selectionBox.offsetHeight
      }).then((canvas: HTMLCanvasElement) => {
        const screenshotUrl = canvas.toDataURL();
        this.urltoFile(screenshotUrl, 'sample' + (Math.random() * 10000) + '.png', 'image/png').then((e: any) => {
          this.addFileLabel(screenshotUrl, e);
        }).catch((e) => {
          console.error('Error capturing screenshot:', e);
        })
      });
    }
  }

  urltoFile(url: any, filename: any, mimeType: any){
    if (url.startsWith('data:')) {
        var arr = url.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        var file = new File([u8arr], filename, {type:mime || mimeType});
        return Promise.resolve(file);
    }
    return fetch(url)
        .then(res => res.arrayBuffer())
        .then(buf => new File([buf], filename,{type:mimeType}));
}

  addFileLabel(base64: any, file: any){
    this.matDialog.open(AddFileModalComponent, {
      hasBackdrop: true,
      disableClose: true, width: '500px', maxHeight: '400px'})
      .afterClosed().subscribe(val => {
        if(val != false){
          this.paradeImgArray.push({'base64': base64, 'file':  file, 'label': val});
          this.messageService.setParadeImage(this.paradeImgArray);
        }
    });
  }

  deleteItem(i: any){
    this.paradeImgArray.splice(i, 1);
    this.messageService.setParadeImage(this.paradeImgArray);
  }

  clear(){
    this.paradeImgArray = [];
    this.messageService.setParadeImage(this.paradeImgArray);
  }

  navigateTo(url: any){
    this.router.navigateByUrl(url);
  }

  openDetail(){
    this.paradeImgArray = [];
    this.currentCaptureStatus = !this.currentCaptureStatus;
    this.messageService.setCaptureStatus(this.currentCaptureStatus);
    this.router.navigateByUrl('parade/detail')
  }

  createParade(){
    this.matDialog.open(ParadeCreateModalDialogComponent, {width: '500px', maxHeight: '400px', data: {paradeData: this.paradeImgArray}})
      .afterClosed().subscribe(id => {
        if(id){
          this.paradeImgArray = [];
          this.currentCaptureStatus = !this.currentCaptureStatus;
          this.messageService.setCaptureStatus(this.currentCaptureStatus);
          this.router.navigateByUrl('parade/detail/'+ btoa(id + ""))
        }
    });
  }

  mapParade(){
    this.matDialog.open(ParadeExistingModalDialogComponent, {width: '500px', maxHeight: '400px', data: {paradeData: this.paradeImgArray}})
      .afterClosed().subscribe(id => {
        if(id){
          this.paradeImgArray = [];
          this.currentCaptureStatus = !this.currentCaptureStatus;
          this.messageService.setCaptureStatus(this.currentCaptureStatus);
          this.router.navigateByUrl('parade/detail/'+ btoa(id + ""))
        }
    });
  }
  
}
