import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DataService } from '../../services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { isArray } from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ProjectService } from '../../services/project.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('rotatedState', [
      state('arrowUp', style({ transform: 'rotate(180deg)' })),
      state('arrowDown', style({ transform: 'rotate(0)' })),
      transition('arrowUp => arrowDown', animate('1s linear')),
      transition('arrowDown => arrowUp', animate('1s linear'))
    ])
  ]
})
export class TreeComponent implements OnInit {

  staticText: any = (textConfiguration as any).default;
  @Input() treearray: Array<any> | any;
  @Input()  treearraycopy:Array<any> | any; 
  @Input() treeindex: number | any;
  @Input() showvalue: boolean = false;
  @Input() formShow: boolean = false;
  @Input() formvalidation: any;
  @Input() treecardtype: String | any;
  @Input() parentTagName: string = "treeParent";
  @Input() subParentTagname: string = "treeSubParent";
  @Input() formTagName: string = "treeWithParent";
  @Input() addicon: boolean = false;
  @Input() removeicon: boolean = false;
  @Input() deleteicon: boolean = false;
  @Input() treeclass: string | any;
  @Input() filterTreeList: boolean = false;
  @Input() filterTreeListKey: string = ''
  @Input() filterTreeListKeyValue: boolean | any;
  @Input() formDisableStatus: boolean = false;
  @Input() formInputDisable: boolean = false;
  @Input() RiskLogform: boolean = false;
  @Input() formArray: any;
  @Input() isOKRLog: any = false;
  @Input() projectId:any;
  @Input() projectWorkType:any;
  /* ----Permissions for buttons------ */
  @Input() treeAddPermission: any;
  @Input() treeRemovePermission: any;
  @Input() treeDeletePermission: any;
  @Input() disableEdit:boolean = false;

  @Output() addkeyresult = new EventEmitter<{}>();
  @Output() deletekeyresult = new EventEmitter<{}>();
  @Output() formData = new EventEmitter<any>();
  @Output() formDatanew = new EventEmitter<any>();
  @Output() infobuttonResult = new EventEmitter<{}>();

  @Output() saveRisk = new EventEmitter<any>();

  randomindex: number | any;
  parentowners: string | any;
  childowners: String | any

  FormArray: any[] = [];

  changeText: boolean = false;
  treechildlength: boolean = false;

  parentTree: boolean[] = new Array().fill(false);
  subParentTree: boolean[] = new Array().fill(false);

  formParentTree: boolean[] = new Array().fill(false);
  formSubParentTree: boolean[] = new Array().fill(false);
  measurmentForm: boolean[] = new Array().fill(false);
  fitlerdata: any;
  normalState: any;
  filterFormArrayList: any;
  selectedRiskDetails:any;
  riskHistory:any=[];

  measureofSuccessData: any;
  @Input() isCentrePane: any;
  @Input() showChildOnly:boolean=false;
  @Input() isRiskUpdate:boolean=false;

  @Input() frequencyOptions: any = [];
  @Input() unitOptions: any = [];

  @Input() riskSetupFormArray :any;
  selectedToeData:any={};

  mitigationFormArray = this.fb.group({
    mitigationList:this.fb.array([]),



})


selectedMitigatinIndex:any=0;
selectedBranch:any;


  riskResponse:any[]=[{"id":1,"display_name":"Accept"},{"id":2,"display_name":"Transfer"},{"id":3,"display_name":"Mitigate"}]






  constructor(private dataservice: DataService, public dialog: MatDialog,private spinner: NgxSpinnerService,
    private permissionsService: NgxPermissionsService, private projectService:ProjectService, private toastr:ToastrService,public fb: FormBuilder
  ) { 

  }




  get riskSetupList(){
    return this.riskSetupFormArray.controls["riskSetupList"] as FormArray;

  }
  get mitigationListLocal(){
    return this.mitigationFormArray.controls["mitigationList"] as FormArray;

  }

   mitigationList(index:any){
    return this.riskSetupList.controls[index].get('mitigationList') as FormArray;
    //return this.riskSetupFormArray.controls["mitigationList"] as FormArray;

  }

// addRiskSetupData() {
//     const riskSetupForm = this.fb.group({
//       impact: ['', Validators.required],
//       likelihood: ['', Validators.required],
//       owner:['',Validators.required],
//       duedate:['',Validators.required],
//       description:['',Validators.required],
//       phase:['',Validators.required],
//       response:['',Validators.required],
//       status:['',Validators.required],
//       mitigation:[{ value: 'Mitigation Plan', disabled: true },Validators.required],
//       transferTo:['']
//       });
//     this.riskSetupList.push(riskSetupForm);
//   }

  addMitigationData(index:any,data:any,isSeleted?:boolean) {
    const mitigationData = this.fb.group({
      id: [data.id],
      mitigationtask: [{value:data.action_tasks, disabled: true }],
      duedate:[],
      risk:[data.risk],
      isSelected:[isSeleted?true:false]
      });
    this.mitigationList(index).push(mitigationData);
    this.mitigationListLocal.push(mitigationData)

   

   
  }

  mitigationTaskSetup(index:any,isSelected?:boolean){
    this.selectedBranch = this.treearray[index];
    if(this.treearray[index].action_plan){
      this.mitigationFormArray =this.fb.group({
        mitigationList:this.fb.array([]),
     })
    this.treearray[index].action_plan.forEach((element:any) => {

      this.addMitigationData(index,element,isSelected);


       
     });
    }
   }
    validateMitigation(){
      let index = this.selectedMitigatinIndex;
       if(this.mitigationList&&this.mitigationList(index).controls&&this.mitigationList(index).controls.length>0){
        for (let subindex = 0; subindex < this.mitigationList(index).controls.length; subindex++) {
          const element = this.mitigationList(index).controls[subindex];
          if(element.get('isSelected')?.value==true){
            let formattedDate=formatDate(element.get('duedate')?.value, 'yyyy-MM-dd', 'en-US') || formatDate(new Date(), 'yyyy-MM-dd', 'en-US')
            if(!element.get('duedate')?.value||!formattedDate){
              this.toastr.error("Please fill all required fields in mitigation","Required Field");

               return
            }
            }
        
        }
      }
      this.dialog.closeAll();
    }

   saveRiskProperties(index:any){
  
     if(this.riskSetupList.controls[index].valid){
     let mitigationlist:any[]=[];
     let riskTransferProp:any;
     if(this.riskSetupList.controls[index].get('response')?.value=='Mitigate'){
     if(this.mitigationList&&this.mitigationList(index).controls&&this.mitigationList(index).controls.length>0){
          for (let subindex = 0; subindex < this.mitigationList(index).controls.length; subindex++) {
            const element = this.mitigationList(index).controls[subindex];
            if(element.get('isSelected')?.value==true){
              let formattedDate=formatDate(element.get('duedate')?.value, 'yyyy-MM-dd', 'en-US') || formatDate(new Date(), 'yyyy-MM-dd', 'en-US')
              if(!formattedDate){
                this.toastr.error("Please fill all required fields in mitigation","Required Field");
  
                 return
              }
             // let formattedDate=formatDate(element.get('duedate')?.value, 'yyyy-MM-dd', 'en-US') || formatDate(new Date(), 'yyyy-MM-dd', 'en-US')
              let request =  {"risk_mitigation": element.get('id')?.value,"planned_end_date":formattedDate,"is_active":true }
              mitigationlist.push(request);
            }else{
              let request =  {"risk_mitigation": element.get('id')?.value,"planned_end_date":null,"is_active":false }
              mitigationlist.push(request); 
            }
          
          }
        }
        }
          if(this.riskSetupList.controls[index].get('response')?.value=='Transfer'){
            riskTransferProp= {
              "first_name": this.riskSetupList.controls[index].get('transferTo')?.value.first_name,
              "last_name": this.riskSetupList.controls[index].get('transferTo')?.value.last_name,
              "mail": this.riskSetupList.controls[index].get('transferTo')?.value.mail
                }
          }

      let payload ={ "project_id": this.projectId,
                    "risks_data": [
                      {
                    "uuid": this.treearray[index].risk_domain_uuid,
                    "risk_subdomain": [
                      {
                      "uuid": this.treearray[index].risk_sub_domain_uuid,
                      "risk": [
                        {
                        "uuid": this.treearray[index].risk_uuid,
                        "risk_owner": {
                        "first_name": this.riskSetupList.controls[index].get('owner')?.value.first_name,
                        "last_name": this.riskSetupList.controls[index].get('owner')?.value.last_name,
                        "mail": this.riskSetupList.controls[index].get('owner')?.value.mail
                          },
                        "due_date": formatDate(this.riskSetupList.controls[index].get('duedate')?.value, 'yyyy-MM-dd', 'en-US') || formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
                        "status": this.riskSetupList.controls[index].get('status')?.value,
                        "risk_mitigation": "",
                        "impact_description": this.riskSetupList.controls[index].get('description')?.value,
                        "risk_impact": this.riskSetupList.controls[index].get('impact')?.value,
                        "risk_likelihood": this.riskSetupList.controls[index].get('likelihood')?.value,
                        "risk_phase": this.riskSetupList.controls[index].get('phase')?.value,
                        "risk_response": this.riskSetupList.controls[index].get('response')?.value,
                        "risk_transfer":riskTransferProp
                      }
                    ]
                  }
                  ]
                  }
                ],
                        "action_plan": mitigationlist,
                         "project": this.projectId
                            }
                            this.formDatanew.emit(payload);
                          }else{
                            this.toastr.error("Please fill all required fields","Required Field");
                          }
 }


  ngOnInit(): void {
    this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem('permission') || '{}').permissions);
    if (this.treearray || this.treearray?.length !== 0 && this.formShow) {
      if(this.treecardtype=='formtreeokr'){

      }else{
        if (this.treearray.category_uuid)
        this.updateFormArray();
      else
        this.updateFormArrayRiskLog();
      }
     
        
    }
   // this.setupFormArrayForRiskSetup();
  }
   setupFormArrayForRiskSetup(){
     console.log(this.treearray)
    this.treearray.forEach((element:any,index:any) => {
  element.risk_sub_domain.forEach((element1:any) => {
    

    element1.risk.forEach((element2:any) => {
      console.log(element2)
    });
  });

     });
    
   }

   getRiskMitigations(riskId:any,index:any,modal: any, evt: Event){
     this.selectedMitigatinIndex = index;
     if(!this.treearray[index].action_plan||this.treearray[index].action_plan.length==0){
    this.spinner.show();
    this.projectService.getRiskMitigations(riskId).subscribe((getRiskLogs: any) => {
      this.spinner.hide();
      if(!getRiskLogs||getRiskLogs.length==0){
        this.toastr.error("Mitigation Plans not setup for this risk","Setup Mitigation!")
      return
      }
      this.treearray[index].action_plan = getRiskLogs;
      this.mitigationTaskSetup(index)
      this.dialog.open(modal, { panelClass: "measure-modal",disableClose:true });
      evt.stopPropagation();
    },err=>{
      this.spinner.hide()
      this.toastr.error("There is some error while fetching mitigations.")
     

    }) 
  }else{
    this.mitigationFormArray = this.fb.group({mitigationList:this.fb.array([])})
     this.mitigationList(index).controls.forEach(element => {
       this.mitigationListLocal.push(element)
     });
    this.dialog.open(modal, { panelClass: "measure-modal" ,disableClose:true});
    evt.stopPropagation();
  }
   }

  private _filterArray(value: any, arr: any = [], key: any): string[] {
    const filterValue = value.toLowerCase();
    return arr.filter((tag: any) => tag?.[key]?.toLowerCase().includes(filterValue));
  }
  //--------------------below function for patch form values in terms page (opportunity)-----------------
  updateFormArray() {
    for (var category of this.treearray?.term_sub_category) {
      for (var subCategory of category.term_measurement_criteria) {
        this.setMeasurementCriteriaId(subCategory);
      }
    }
  }
  //--------------------below function for patch form values in risk log-----------------
  updateFormArrayRiskLog() {
    if (this.treearray?.risk_sub_domain) {
      for (var category of this.treearray?.risk_sub_domain) {
        for (var subCategory of category?.risk) {
          if (subCategory?.uuid && subCategory?.risk_owner && subCategory?.director && subCategory?.delegate)
            this.setMeasurementCriteriaId(subCategory);
        }
      }
    }
  }

  filterData(value: any) {
    const filterValue = value.toLowerCase();
    return this.formArray.filter((option: any) => option.toLowerCase().includes(filterValue));
  }
  setMeasurementCriteriaId(subCategory: any) {
    this.pushLastValue(this.dataservice.FormArray, subCategory);
    this.formData.emit(this.dataservice.FormArray);
  }

  pushLastValue(arr: any, obj: any) {
    var index: any;
    if (obj?.measurement_criteria_uuid) {
      index = arr.findIndex((e: any) => e.measurement_criteria_uuid === obj.measurement_criteria_uuid);
    } else {
      index = arr.findIndex((e: any) => e.risk_uuid === obj.risk_uuid);
    }
    if (index === -1) {
      arr.push(obj);
    } else {
      arr[index] = obj;
    }
    return arr
  }

  AddRemoveColumn(child: any, parentStauts: any) {
    //----------Below code assigned to get Goal's / parent  " is_Custom " status to decide whether the child data is from recommended / Custom-----------//
    child['goal_is_custom'] = parentStauts ?? false;
    //----------Below code will set filter key which is "is_custom" it provide boolean value in order to sort difficult in data manipulating in Opportunity/list component ----------//
    if (this.filterTreeListKey) {
      child[this.filterTreeListKey] = this.filterTreeListKeyValue;
    }
    this.addkeyresult.emit(child);
  }

  AddRemoveColumnOKR(subchild:any,treeArray:any,child?:any){
    this.addkeyresult.emit({"subchild":subchild,"treeArray":treeArray,"child":child});

  }
  infoButton(child:any){
    this.infobuttonResult.emit(child)
  }

  DeleteKey_Toe(child: any) {
    this.deletekeyresult.emit(child);
  }

  DeleteKey_Okr(parentId: any, indx: any){
    let childObj = {key_result_id: parentId, child_key_result_id: this.treearray?.key_result.child_key_results[indx].id};
    this.treearray?.key_result.child_key_results.splice(indx, 1);
    this.deletekeyresult.emit(childObj);
  }

  displayFn(data: any): string {
    if (data && data?.name) {
      return data && data?.name ? data?.name : data?.mail;
    } else if (data && data?.label) {
      return data && data?.label ? data?.label : data?.action_complete_label;
    } else
      return data && data?.full_name ? data?.full_name : data?.mail;
  }
  displayFn1(data: any): string {
    return data && data?.display_name ? data?.display_name : data?.name;
  }
  openMeasureofSuccessModal(child: any, parentStauts: any, modal: any, evt: Event) {

    this.measureofSuccessData = child?.measure_of_success && child?.measure_of_success.length>0 ? child?.measure_of_success : (child?.mitigation_plan??[]);
    this.measureofSuccessData = this.measureofSuccessData?.map((e: any) => {
      e['reference_link'] = isArray(e?.reference_link) ? e?.reference_link : JSON.parse(e?.reference_link?.replace(/\'/g, '"'))
      return e;
    })
    this.dialog.open(modal, { panelClass: "measure-modal" });
    evt.stopPropagation();

    
  }
  openMitigationModal(child: any, parentStauts: any, modal: any, evt: Event,index:any) {

    // this.measureofSuccessData = child?.measure_of_success && child?.measure_of_success.length>0 ? child?.measure_of_success : (child?.mitigation_plan??[]);
    // this.measureofSuccessData = this.measureofSuccessData?.map((e: any) => {
    //   e['reference_link'] = isArray(e?.reference_link) ? e?.reference_link : JSON.parse(e?.reference_link?.replace(/\'/g, '"'))
    //   return e;
    // })
    this.getRiskMitigations(child.uuid?child?.uuid:child?.risk_uuid, index,modal,evt);
    
    
   

    
  }
  openToEModal(mos: any,  modal: any) {
    this.selectedToeData = mos;
    this.dialog.open(modal, {panelClass:"mw-650"});
  }


  openRiskHistory(data: any,modal:any){   
    this.selectedRiskDetails = data; 
    this.spinner.show();
    this.projectService.getRiskHistory(data?.id).subscribe((res)=>{
      this.spinner.hide();
      if(res){
        this.riskHistory = res;
        this.dialog.open(modal,{panelClass:"mw-650"});
      }else{
        this.toastr.error("Unable to fetch data...please try again later...") 
      }
    })
  }
  
  openRiskDetails(data: any,modal:any){   
    this.selectedRiskDetails = data; 
    this.dialog.open(modal,{panelClass:"mw-650"});
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  onClickSaveRisk(risk:any){
    this.saveRisk.emit(risk);
  }

  alreadyLinked(data: any){
    let bool = false;
    data?.child_key_results?.forEach((element: any) => {
      if(!element.is_linked_key_result){
        bool = true;
      }
    });

    return bool;
  }
}
