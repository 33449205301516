import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramService } from '../../services/program.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-left-side-menu',
  templateUrl: './left-side-menu.component.html',
  styleUrls: ['./left-side-menu.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class LeftSideMenuComponent implements OnInit, OnDestroy {
  staticText: any = (textConfiguration as any).default;
  opportunityId: any;
  tips = true;
  showIdea = false;
  convertedID='';
  oppCurrentStatus:any={};
  percent=0;
  colorCode='#FFC000';
  isRedirectFrom = false;
  projectID:any='';
  sub:any;
  firstSepperLable = this.staticText?.opportunity?.create_edit


  @ViewChild('stepper') stepper: MatStepper | any;
  stepName = localStorage.getItem('stepLabel');
  opportunityDetails: any;
  user: any;
  
  showTip() {
  }

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private ps: ProgramService,
    private spinner:NgxSpinnerService,
    private cdRef:ChangeDetectorRef
  ) {
    console.log(this.router, route);
    
   }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.sub = this.route
      .queryParams
      .subscribe((params:any) => {
        this.isRedirectFrom = params['redirectFrom'] || false;
        this.projectID = params['id'];
      });
      this.route.params.subscribe((res: any) => {
        this.opportunityId = atob(res.id);
        this.convertedID = res.id;
        this.getOpportunityById(this.opportunityId);
      })
  }


  getOpportunityById(id: any) {
    this.ps.getOppStatus(this.opportunityId).subscribe((res: any) => {
      this.opportunityDetails = res;
      this.oppCurrentStatus = this.opportunityDetails?.status;
      this.ps.setStatusValue(this.oppCurrentStatus);
      if(this.oppCurrentStatus?.slug == 'opportunity_draft'){  
        this.percent=20;
        this.colorCode='#dd2c54';
      }else if(this.oppCurrentStatus?.slug == 'opportunity_created' || this.oppCurrentStatus?.slug =='opportunity_modified'){  
        this.percent=40;
        this.colorCode='#daa479';
      }else if(this.oppCurrentStatus?.slug == 'opportunity_published'){  
        this.percent=50;
        this.colorCode='#1a85d6';
      }else if(this.oppCurrentStatus?.slug == 'opportunity_evaluating'){  
        this.percent=75;
        this.colorCode='#FFC000';
      }else if(this.oppCurrentStatus?.slug == 'opportunity_sow_signed'){  
        this.percent=100;
        this.colorCode='#07b797';
      }else if(this.oppCurrentStatus?.slug == 'opportunity_cancelled'){  
        this.percent=100;
        this.colorCode='#dd2c54';
      }  
    });
  }

  ngAfterViewInit() {
    this.setStepperData();
    this.ps.getStatusValue().subscribe((newValue) => {
      if(newValue?.slug !=this.oppCurrentStatus?.slug){
        this.oppCurrentStatus = newValue;
        if(newValue?.slug == 'opportunity_draft'){  
          this.percent=20;
          this.colorCode='#dd2c54';
        }else if(newValue?.slug == 'opportunity_created' || newValue?.slug =='opportunity_modified'){  
          this.percent=40;
          this.colorCode='#daa479';
        }else if(newValue?.slug == 'opportunity_published'){  
          this.percent=50;
          this.colorCode='#1a85d6';
        }else if(newValue?.slug == 'opportunity_evaluating'){  
          this.percent=75;
          this.colorCode='#FFC000';
        }else if(newValue?.slug == 'opportunity_sow_signed'){  
          this.percent=100;
          this.colorCode='#07b797';
        }else if(newValue?.slug == 'opportunity_cancelled'){  
          this.percent=100;
          this.colorCode='#dd2c54';
        }
      }    
   });    
  }
  setStepperData() {
    let lastUrlSegment = this.router.url.split('/').pop();
    
    if (lastUrlSegment == 'proposal-evaluation' || lastUrlSegment == 'summary' || lastUrlSegment == 'award-deal') {
      if(this.user?.is_vendor){
        this.stepper.selectedIndex = 0;
      }else{
        this.stepper.selectedIndex = 1;
      }
      this.firstSepperLable = this.staticText?.opportunity?.edit;
    }
    else if (lastUrlSegment == 'setup' || lastUrlSegment == 'scope' || lastUrlSegment == 'terms' || lastUrlSegment == 'more-info' || lastUrlSegment =="preview" || lastUrlSegment=="evaluation") {
      this.stepper.selectedIndex = 0;
    }
    this.cdRef.detectChanges(); 
  }

  clickDeliveryContract() {
    this.router.navigate([`toe/${btoa(this.opportunityId)}/delivery-contract`]);
  }

  clickAddcontract() { this.router.navigate([`toe/${btoa(this.opportunityId)}/add-terms`]); }
  onStepChange(evt:any){
    console.log(evt);
    // if(evt.selectedIndex ==0){
    //   this.router.navigate([`rfx/${btoa(this.opportunityId)}/opportunity/preview`]);
    // }else if(evt.selectedIndex ==1){
    //   this.router.navigate([`getproposal/${btoa(this.opportunityId)}/proposal-evaluation/`]);
    // }
  }
  navigateTO(page:string){   
    //this.spinner.show();
    this.ps.getOppStatus(this.opportunityId).subscribe((res: any) => {
     // this.spinner.hide();
      this.ps.setStatusValue(res?.status);
      if(this.isRedirectFrom){
        this.router.navigate([`/rfx/${this.convertedID}/opportunity/${page}`],{queryParams: { redirectFrom: 'Project',id:  this.projectID  }});
      }else{
        this.router.navigate([`/rfx/${this.convertedID}/opportunity/${page}`]);
      }
    },err=>this.spinner.hide());
  }
  
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}

