import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-textbox',
  templateUrl: './autocomplete-textbox.component.html',
  styleUrls: ['./autocomplete-textbox.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class AutocompleteTextboxComponent implements OnInit {

  @Input() inputname: string | FormGroupName | any;
  @Input() iconhide: boolean = false;
  @Input() showvalue: boolean = false;
  @Input() autocompletearray: Array<any> | any;
  @Output() subPortfolio = new EventEmitter<number>();
  @Input() autocompleteFormgroup: FormGroup | any;
  @Input() valueselect: any;


  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]> | any;
  filterOptionSubPortfolio: Observable<any[]> | any;
  subportfolioid: any;

  ngOnInit() {
  
    this.filteredOptions = this.myControl!.valueChanges.pipe(
      
      
      startWith(''),
      map((value: any) =>{ return typeof value === 'string' ? value : value?.display_name}),
      map((name: any) => name ? this._filterArray(name,this.autocompletearray,'display_name') : this.autocompletearray.slice())
    );
    let autoformconrotls = this.autocompletearray;

    this.autocompleteFormgroup = new FormGroup(
      { autoformconrotls: new FormControl(null, Validators.required) }
    )
  }
  forminputs() {
    this.inputname = new FormControl(null, Validators.required);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option: any) => option.toLowerCase().includes(filterValue));
  }

  changePortfolio(e: any) {
    this.subportfolioid = e.id;
    this.subPortfolio.emit(e.id);
  }

  displayFn(data: any) {
    return data && data.display_name ? data.display_name : '';
  }
  // filterData(){
  //   this.filterSubPortfoliolist = this.searchSubPortCtrl!.valueChanges
  //   .pipe(
  //     startWith(''),
  //     map((value: any) => typeof value === 'string' ? value : value?.display_name),
  //     map((name: any) => name ? this._filterArray(name,this.subportfolio,'display_name') : this.subportfolio.slice())
  //   );
  // }
  private _filterArray(value: any,arr:any=[],key:any): string[] {
    const filterValue = value.toLowerCase();
    return arr.filter((tag: any) => tag?.[key]?.toLowerCase().includes(filterValue));
  }
}
