import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { WorkForceService } from 'src/app/shared/services/work-force.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-gnews',
  templateUrl: './gnews.component.html',
  styleUrls: ['./gnews.component.scss']
})
export class GnewsComponent implements OnInit {
  browseAIData:any = [];
  @Input() pagename: string = '';
  @Input() display_header: Boolean = true;
  llmformattedresponse: any;
  isExpanded: any;
  query_string: any

  llmresponse: string;
  llmlinks: any;
  loading: boolean = false;
  calledOnce:boolean = true;

  constructor(
    private spinner: NgxSpinnerService,
    private workForceService: WorkForceService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.messageService.getGnews().subscribe((res: any) => {
      this.pagename = res
      if(this.calledOnce){
        this.getBrowseAIData();  
      }
      this.calledOnce = false;
    })
  }
  
  getGMapRandomColor() {
    return 'hsla(' + Math.floor(Math.random()*360) + ', 50%, 25%, 90%)';
  } 

  openNewWindow(url:any){    
    const newWindow = window.open(url, '_blank', 'width=600,height=400');
  }


   getBrowseAIData(){
    this.spinner.show();
    this.loading = true;
     this.workForceService.getBrowseAIData(this.pagename).subscribe((res:any)=>{
      this.spinner.hide();
      this.query_string = res.query_string;
      this.llmresponse = res.summary;
      this.llmlinks = res.data;
      
      this.formatCurated() 
      this.loading = false;
    });
  }
  
  formatCurated(){
    this.llmformattedresponse = [];
    this.llmresponse.split(".").forEach((e: any) => {
      if(e.length > 0){
        this.llmformattedresponse.push(this.replaceCitations(e.trim(), this.llmlinks))
      }
    })
  }

  replaceCitations(text: any, citationsArray:any) {
    return text.replace(/\[citation:(\d+)\]/g, (_: any, index: any) => {
      const citationIndex = parseInt(index);
      return `<sup><a href="${citationsArray[citationIndex]?.url}" target="_blank" class="citation-links" onclick="window.open('${citationsArray[citationIndex]?.url}', '_blank', 'width=600,height=400')"><span onclick="alert('sa')">${citationIndex}</span></a></sup>` || `[citation:${index}]`;
    });
  }
  
}

