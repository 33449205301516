import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class FooterComponent implements OnInit {
  iframeEmbedded: boolean = false;

  constructor(private messageService: MessageService) {
  }

  ngOnInit(): void { 
    this.messageService.getIframeData().subscribe((res: any) => {
      this.iframeEmbedded = typeof res != 'object' ? true : false;
    })
  }
}
