import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { SurveyService } from '../../../shared/services/survey.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { startWith,map, filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
 import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter} from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewEncapsulation } from '@angular/core';

 export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    let day: any = date.getDate().toString();
    day = +day < 10 ? '0' + day : day;
    let month: any = (date.getMonth() + 1).toString();
    month = +month < 10 ?'0' + month : month;
    let year:any = date.getFullYear();      
    let shortMonth = date.toLocaleString('en-us', { month: 'short' }); 
    if (displayFormat === 'input') {
      return `${day} ${shortMonth} ${year}`
    }
    else if (displayFormat === 'inputMonth') {
      return `${shortMonth} ${year}`
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
 display: {
    dateInput: 'input',
    monthYearLabel: 'inputMonth',
    dateA11yLabel: { month: 'short', year: 'numeric', day: 'numeric' },
    monthYearA11yLabel: { month: 'short', year: 'numeric', day: 'numeric' },
  },
};
@Component({
  selector: 'app-publish-survey',
  templateUrl: './publish-survey.component.html',
  styleUrls: ['./publish-survey.component.scss'],
  providers: [DatePipe,
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ],
  encapsulation:ViewEncapsulation.Emulated
})
export class PublishSurveyComponent implements OnInit {
  surveyForm:FormGroup|any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags:any=[];
  searchCtrl = new FormControl('');
  surveyId:any;
  distributorList:any =[]
  filterOptions: Observable<any[]> | any;
  staticText: any = (textConfiguration as any).default;
  constructor(private surveyService:SurveyService,private spinner: NgxSpinnerService, private router:Router, public dialogRef: MatDialogRef<PublishSurveyComponent>,
  private toastrService: ToastrService, private activatedRoute:ActivatedRoute, private datepipe:DatePipe) { 
    this.surveyForm = new FormGroup({
      survey_start_date: new FormControl('',Validators.required),
      survey_end_date: new FormControl('',Validators.required),
      respondents: new FormControl('',Validators.required),
    })
  }

  ngOnInit(): void {
    // this.activatedRoute.params.subscribe(params => {
    //   this.surveyId = params.id;
    // });
    this.getDistributorsList();
    this.surveyService.getFormId().subscribe((id)=>this.surveyId = id);
  }
  getDistributorsList(){
    this.surveyService.getDistributors().subscribe((response: any) => {
      this.spinner.hide()
      console.log(response);
      this.distributorList = response['records'].find((i: any) => i.field_name == "opportunity_team").field_values;      
      this.filterOptions = this.searchCtrl!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this._filter(name) : this.distributorList.slice())
        );
    },err=>{
      this.spinner.hide()
    });
  }
  
  _filter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.distributorList.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }
  addTag(event: MatChipInputEvent|any,searchInput:any): void {
    const value = event.option.value || ''
    if (value) {
      this.tags.push(value);
    }
    searchInput.value = '';
   this.searchCtrl.setValue(null);
   this.distributorList = this.distributorList.filter((o:any) => o != value);
   this.distributorList = this.distributorList ? this.distributorList :[]
   this.filterOptions = this.filterOptions.pipe(filter((o:any)=> o !=value));
    //this.selectedTags.push({ key_word: event.value });
  }

  removeTag(tag: any): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
      this.distributorList.push(tag) 
      this.filterOptions = this.filterOptions.pipe(filter((o:any)=> o !=tag));
      //this.selectedTags.splice(index, 1);
    }
  }
  
  publishSurvey(){    
    this.surveyForm.get('respondents').setValue(this.tags);
    if(this.surveyForm.invalid){
      return;
    }else if(new Date(this.surveyForm.get('survey_start_date').value) > new Date(this.surveyForm.get('survey_end_date').value)){
      return;
    }
    else{
      this.spinner.show();
      let payload=this.surveyForm.value
      payload['survey_start_date'] = this.datepipe.transform(this.surveyForm.value.survey_start_date, 'yyyy-MM-dd');
      payload['survey_end_date'] = this.datepipe.transform(this.surveyForm.value.survey_end_date, 'yyyy-MM-dd');
      this.surveyService.publishSurvey(this.surveyId ,payload).subscribe((response: any) => {
        this.spinner.hide()
        if(response){
          this.toastrService.success("Survey Published Succesfully");
          this.dialogRef.close(true);
          //this.surveyService.setActiveTab('questions')
          //this.router.navigate(["/BVT/list"])
        }        
      },err=>{
        this.spinner.hide()
      });
    }
  }
  cancelForm(){this.dialogRef.close(false);}

}
