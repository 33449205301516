import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { SwiperComponent } from "swiper/angular";
import { BehaviorSubject } from 'rxjs';
import * as textConfiguration from 'src/assets/static-text-configuration.json';

// Below imports are rquired for swiper caorusel
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
  SwiperOptions,
} from 'swiper';
import { ProgramService } from '../../services/program.service';
import { ViewEncapsulation } from '@angular/core';
// Below swipercore should be used inorder to use swiper
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class CarouselComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  // with below line we can control or use swiper element
  @ViewChild('swiperRef', { static: false }) swiperRef?: SwiperComponent | any;

  // below will get custom carousel data
  @Input() customcarousel: Array<any> | any=[];

  // below will get swiper filter options 
  @Input() swiperview: number | any;
  @Input() swiperspace: number | any;
  @Input() swipernav: boolean | any;
  @Input() swiperpage: boolean | any;
  @Input() swiperscroll: boolean | any;
  @Input() swiperAheight: boolean | any;
  @Input() swiperAplay: boolean | any;
  @Input() swipertouch: boolean | any;
  @Input() stylecss: object | any;
  @Output() selectedPortfolio = new EventEmitter<number>();

  // custom variables
  slides$ = new BehaviorSubject<string[]>(['']);
  config: SwiperOptions | any;
  showfilter = ""

  carouselbtn: any[] = [
    { "name": "all of IT", "icon": "/assets/images/carousel/All.svg", "color": "", "backgroundcolor": "", "id": 1 },
    { "name": "channels", "icon": "/assets/images/carousel/channels.svg", "color": "", "backgroundcolor": "", "id": 2 },
    { "name": "Corp & Int'l Tech", "icon": "/assets/images/carousel/Corp.svg", "color": "", "backgroundcolor": "", "id": 3 },
    { "name": "Enterprise Technology Services", "icon": "/assets/images/carousel/EnterpriseTech.svg", "color": "", "backgroundcolor": "", "id": 4 },
    { "name": "Information Security", "icon": "/assets/images/carousel/Information Security.svg", "color": "", "backgroundcolor": "", "id": 5 },
    { "name": "Infrastructure", "icon": "/assets/images/carousel/Infrastructure.svg", "color": "", "backgroundcolor": "", "id": 6 },
    { "name": "IT Transformation", "icon": "/assets/images/carousel/IT Transformation.svg", "color": "", "backgroundcolor": "", "id": 7 },
    { "name": "Operations Technology", "icon": "/assets/images/carousel/Operations Technology.svg", "color": "", "backgroundcolor": "", "id": 8 },
    { "name": "Revenue, Loyalty & DataAnalysis", "icon": "/assets/images/carousel/Revenue.svg", "color": "", "backgroundcolor": "", "id": 9 },
  ]
  buttonClicked: any = '';
  masterPortfolioRecords: any = [];
  constructor(private programService: ProgramService) { }

  ngOnInit() {
    this.buttonClicked = '';
    this.swipercarouseloptions();
    this.getAllportfolio();
  }
  getAllportfolio() {
    this.programService.comparePorfolio().subscribe((getAllProtfolios: any) => {
      this.masterPortfolioRecords = getAllProtfolios;
    })
  }

  //swiper options
  swipercarouseloptions() {
    this.config = {
      slidesPerView: this.swiperview || 4,
      spaceBetween: this.swiperspace || -10,
      navigation: this.swipernav || false,
      pagination: this.swiperpage || false,
      scrollbar: this.swiperscroll || false,
      autoHeight: this.swiperAheight || true,
      autoplay: this.swiperAplay || false,
      allowTouchMove: this.swipertouch || false
    };
  }
  onSwiper(swiper: any) {
    console.log(swiper);
  }

  getSlides() {
    this.slides$.next(Array.from({ length: 600 }).map((el, index) => `Slide ${index + 1}`));
  }

  breakpoints = {
    640: { slidesPerView: 2, spaceBetween: 20 },
    768: { slidesPerView: 4, spaceBetween: 40 },
    1024: { slidesPerView: 4, spaceBetween: 50 },
  };

  //below can used for swiper slides  
  slides = Array.from({ length: 5 }).map((el, index) => `Slide ${index + 1}`);
  virtualSlides = Array.from({ length: 600 }).map((el, index) => `Slide ${index + 1}`);

  // below can show swiper changes
  onSlideChange() {
  }

  // both below function for custom fitlers..............//

  slideNext() {
    // slide Next funtion
    this.swiperRef.swiperRef.slideNext(100);
  }
  slidePrev() {
    // slide pervious funtion
    this.swiperRef.swiperRef.slidePrev();
  }

  carouselbutton(id: any) {
    this.showfilter = "show"
    if (this.buttonClicked === id){ 
      this.buttonClicked = '';
    }
    else {
      this.buttonClicked = id;
    }
    this.selectedPortfolio.emit(this.buttonClicked);

    this.stylecss = {
      background: null,
      color: null
    };
  }
}
