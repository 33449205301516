<app-loading *ngIf="loading"></app-loading>
<iframe [src]="tokenJWT" style="width: 80vw; height: 85vh" frameborder="0"></iframe>
<!-- <div (click)="$event.stopPropagation();">
    <div class="p-4 pb-0">
    <div class="d-flex justify-content-between">
        <h6>Result For : {{queryName}}</h6>
        <mat-icon *ngIf="queryName" (click)="clearMessage()" class="fs-12" style="margin-right: -20px;">close</mat-icon>
    </div>
    <app-loading *ngIf="loading"></app-loading>
        
    <div style="max-height: 400px;overflow: auto;"> 
        <div class="custom-height">
            <div class="custom-table table-responsive" style="max-height: calc(100vh - 400px);">
                <table class="table table-bordered">
                    <thead style="position: sticky; z-index: 1">
                        <tr>
                            <th *ngFor = "let key of answerKeyList" class="text-capitalize">{{alterKeyName(key)}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of answerList">
                            <td *ngFor="let key of answerKeyList">{{ item[key] }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div *ngIf="answerList.length == 0">
        <span>{{emptyTextResult}}</span>
    </div>
</div>
<hr>
<div class="input" style="position:relative;bottom: 0;">
    <input type="text" class="user-input" placeholder="message..." (keyup)="textEnterStarted()" [(ngModel)]="queryName"/>
      <button>
        <i class="fa fa-paper-plane fa-2x" [style.color]="myColorVaraible" (click)="getQueryList()" aria-hidden="true"></i>
      </button>
      <div style="text-align: center;font-size: 10px;padding:2px 8px;">Airo may produce inaccurate information about people, places, or facts</div>
</div>
</div>

                         -->