import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { of } from 'rxjs';
import {tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetProposalService {

  constructor(private http: HttpClient,private transferState: TransferState) { }

  getPropsals(offset: number = 0, end: number = 10, subPortId?: any, searchText?: any, portId?: any, status?: any) {
    let params = `offset=${offset}&end=${end}`;
    if (portId) {
      params = `${params}&portfolio=${portId}`
    } if (subPortId) {
      params = `${params}&portfolio=${subPortId}`
    } if (status) {
      params = `${params}&status=${status}`
    }if (searchText) {
      params = `${params}&search_text=${searchText}`
    }
    return this.http.get(`proposal/get-proposal-list/?${params}`);
  }

  deleteProposal(id: any) {
    return this.http.delete(`proposal/${id}`);
  }

  filterPortfolio(id: any) {
    return this.http.get(`proposal/get-proposal-list/?portfolio=${id}`);
  }
  filterSubPortfolio(portId: any, subPortId: any, program: number = 1) {
    return this.http.get(`proposal/get-proposal-list/?portfolio=${portId}&portfolio=${subPortId}&program=${program}`);
  }
  getProposalOverview(id: any) {
    return this.http.get(`proposal/get-vendor-by-opportunity/?opportunity_id=${id}`);
  }

  getStatus() {
    const detailKey = makeStateKey(`proposal-status-list/`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.get(`proposal/proposal-status-list/`).pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }

  updateReceivedDate(payload: any) {
    return this.http.post(`proposal/update-proposal-received-date/`, payload);
  }

  updateStatus(payload: any) {
    return this.http.post(`proposal/update-proposal-status/`, payload);
  }

  uploadDocument(id: any, payload: any) {
    return this.http.post(`proposal/proposal-file-upload/${id}/`, payload);
  }

  getDocument(id: any, vendorId: any) {
    return this.http.get(`proposal/proposal-file-list/${id}/?vendor_id=${vendorId}`);
  }

  downloadDocument(id: any, payload: any) {
    return this.http.post(`proposal/proposal-file-download/${id}/`, payload, { responseType: 'blob' });
  }

  deleteFile(id: any, payload: any) {
    return this.http.post(`proposal/proposal-file-delete/${id}/`, payload);
  }

  updatePMResponse(id: any, payload: any) {
    return this.http.post(`proposal/create-update-proposal-response/${id}/`, payload);
  }

  getPMResponse(id: any, vendorId: any) {
    return this.http.get(`proposal/get-vendor-proposal-response/${id}/${vendorId}/`);
  }

  getEvaluatorResponse(id: any, vendorId: any) {
    return this.http.get(`proposal/get-evaluator-response/${id}/${vendorId}/`);
  }

  updateEvaluatorResponse(id: any, payload: any) {
    return this.http.post(`proposal/create-update-evaluator-response/${id}/`, payload);
  }

  getScoreRange(id: any) {
    return this.http.get(`opportunity/get-evaluation-settings/?opportunity=${id}`);
  }

  getEvaluation(id: any) {
    return this.http.get(`proposal/get-proposal-evaluation/${id}`)
  }

  getToeSummary(id: any) {
    return this.http.get(`proposal/get-toe-evaluation/${id}/`)
  }

  getOpportunityTermsDetails(id:any){
    return this.http.get(`terms/opportunity-terms/?opportunity_id=${id}`)
  }

  getOpportunityTermsDetailsResponse(id:any, vendor: any){
    return this.http.get(`terms/opportunity-terms/?opportunity_id=${id}&vendor_id=${vendor}`)
  }
  saveProposalEvaluationTerms(payload:any){
    return this.http.post(`proposal/term-offer/`,payload)
  }

  getMappedProjects(id: any) {
    return this.http.get(`opportunity/oppurtunity_mapped_projects/${id}/`)
  }
}
