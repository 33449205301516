import { Component, EventEmitter, Output,Input,SimpleChanges, ViewChild, ElementRef, Renderer2  } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import * as html2canvas from 'html2canvas';

@Component({
    selector: 'app-comments',
    templateUrl: 'comments.component.html',
    styleUrls: ['comments.component.scss'],
})
export class CommentsComponent {
  @Output() dataEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadFileEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() copyFileEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('focusDiv', { static: false }) focusDiv: ElementRef;
  
  constructor(
    private toastrService : ToastrService,
    private renderer: Renderer2
  ){}
  content:any;
  attachementName:any;
  attachement:any;
  @Input() comments: string;
  @Input() refresh: boolean;
  @Input() noEditor:string;
  @Input() copyAllowed: boolean;
    modules = {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
      
          [{ 'size': ['small', false, 'large', 'huge'] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

          [{ 'font': [] }],
          [{ 'align': [] }],
      
          ['clean'],
        ]
      };

      addComment(){
        this.dataEvent.emit({text:this.content,document:this.attachement});
      }

      onFileSelected(event:any){
        const inputElement = event.target as HTMLInputElement;
        if (inputElement.files && inputElement.files.length > 0) {
          const file = inputElement.files[0];
          if (file.size > 5000000) {
            this.toastrService.error('Please upload less than 5MB');
            return;
          }
          this.attachementName = file.name;
          this.attachement = file;
      }
    }

    ngOnInit(): void {
      console.log(this.comments);
    }
    ngOnChanges(changes: SimpleChanges): void {
      if (changes['refresh']) {
        this.attachement ='';
        this.attachementName = '';
        this.content = '';
      }
    }

    getFileType(file:any){
      const ext = file.substring(file.lastIndexOf('.') + 1)
      return  ext?.toLowerCase()
    }
   
    downloadFeedbackDocument(comment:any){
      this.downloadFileEvent.emit(comment);
  
    }

    convertToImage(id: any) {
      if(this.copyAllowed == true){
        var divToCapture = document.getElementById(id);
        let th = this;
        const html2canvasInstance: any = html2canvas;
        html2canvasInstance(divToCapture).then((canvas: any) => {
          canvas.toBlob(async (blob: any) => {
            if (document.hasFocus()) {
              const clipboardItem = new ClipboardItem({ 'image/png': blob });
              await navigator.clipboard.write([clipboardItem]);
            }
          })
        });
      }
    }
}