<div class="accordion" id="accordionExample">
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingZero">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseZero" aria-expanded="true" aria-controls="collapseOne"
                style="font-size: 13px">
                Description
            </button>
        </h2>
        <div id="collapseZero" class="accordion-collapse collapse" aria-labelledby="headingZero"
            data-bs-parent="#accordionExample">
            <div class="accordion-body px-3">
                <p class="desc-style">
                    {{csDetails?.description}}
                </p>
            </div>
        </div>
    </div>
    <div class="accordion-item" *ngIf="csDetails.impact">
        <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                style="font-size: 13px">
                Impact
            </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body px-3">
                <p class="desc-style">{{csDetails?.impact}}</p>
            </div>
        </div>
    </div>
    <div class="accordion-item" *ngIf="csDetails.technologies">
        <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"
                style="font-size: 13px">
                Technology stack/preferences
            </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample">
            <div class="accordion-body px-3">
                <p class="desc-style">{{csDetails?.technologies}}</p>
            </div>
        </div>
    </div>
    <div class="accordion-item" *ngIf="csDetails.submission_date">
        <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree"
                style="font-size: 13px">
                Last date of submission
            </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
            data-bs-parent="#accordionExample">
            <div class="accordion-body px-3">
                <p class="desc-style">{{csDetails?.submission_date | date:'yyyy-MMM-dd'}}</p>
            </div>
        </div>
    </div>
    <div class="accordion-item" *ngIf="csDetails.poc_expectations">
        <h2 class="accordion-header" id="headingFour">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour"
                style="font-size: 13px">
                POC expectations
            </button>
        </h2>
        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
            data-bs-parent="#accordionExample">
            <div class="accordion-body px-3">
                <p class="desc-style">{{csDetails?.poc_expectations}}</p>
            </div>
        </div>
    </div>
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingFive">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive"
                style="font-size: 13px">
                Attachments ({{csDetails?.problem_statement_files.length}})
            </button>
        </h2>
        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
                <div style="overflow-x: scroll">
                    <div class="d-flex flex-row flex-nowrap overflow-auto" *ngIf="csDetails?.problem_statement_files.length > 0" style="padding: 10px 0">
                        <div class="card card-block mx-2" style="width: 150px; height: 150px; display: flex; 
                            align-items: center; justify-content: center; border-radius: 15px;padding:0 4px;" 
                            *ngFor="let x of csDetails?.problem_statement_files; let i = index" (click)="downloadFile(x)">
                            <mat-icon style="position: absolute;top:0;right:4px;">cloud_download</mat-icon>
                                <img *ngIf="x?.file_type == 'doc' || x?.file_type == 'docx'"
                                    src="./assets/images/word.svg" height="40" style="width: 30px;">
                                <img *ngIf="x?.file_type == 'pdf'" src="./assets/images/pdf.svg"
                                    height="40" style="width: 30px;">
                                <img *ngIf="x?.file_type == 'xls' || x?.file_type == 'xlsx'"
                                    src="./assets/images/excel.svg" height="40" style="width: 30px;">
                                <img *ngIf="x?.file_type == 'csv'" src="./assets/images/csv.png"
                                    height="40" style="width: 30px;">
                                <mat-icon
                                    *ngIf="x?.file_type !== 'pdf' && x?.file_type !== 'doc' && x?.file_type !== 'docx' && x?.file_type !== 'xls' && x?.file_type !== 'xlsx' && x?.file_type !== 'csv'"
                                    mat-list-icon class="material-icons my-auto fs-1 text-danger" style="width: 30px;">
                                    description</mat-icon>  
                                <p class="m-0" style="font-size: 10px; text-align: center;">{{x?.file_name}} </p>
                        </div>
                    </div>  
                    <div *ngIf="csDetails?.problem_statement_files.length == 0">
                        No files attached.
                    </div>        
                </div>
            </div>
        </div>
    </div>
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingSix">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix"
                style="font-size: 13px">
                OKR Selected ({{csDetails?.problem_statement_okr.length}})
            </button>
        </h2>
        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
                <div style="overflow-x: scroll">
                    <div class="d-flex flex-row flex-nowrap overflow-auto" style="padding: 2px 0">
                        <div *ngIf="csDetails?.problem_statement_okr.length > 0">
                            <div class="okr-box-style" *ngFor="let okr of csDetails?.problem_statement_okr" [ngStyle]="{'background': okr.color}" >
                                {{okr?.title}}
        
                                <b style="color:#9e9393;font-size: 12px;display:block;">Comment</b>
                                <span style="font-size:12px">{{okr?.comment}}</span>
                            </div>
                        </div>
                        <div *ngIf="csDetails?.problem_statement_okr.length == 0">
                            No OKR selected.
                        </div>
                    </div>         
                </div>
            </div>
        </div>
    </div>
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingSeven">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven"
                style="font-size: 13px">
                Vendor ({{csDetails?.vendor.length}})
            </button>
        </h2>
        <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
                <div class="flex-container-1">
                    <div class="flex-item p-1" *ngFor="let provider of csDetails?.vendor;">
                        <div class="trn-x" [ngStyle]="{'background': provider.color}">
                            <div class="d-flex">
                                <div>
                                    <img *ngIf="provider?.url" src="{{provider?.url}}" style="width:40px;height:40px;border-radius: 50%;"/>
                                    <span class="name-1" *ngIf="!provider.url"   [ngStyle]="{'backgroundColor': provider?.logoColor}">{{getName(provider?.name)}}</span>
                                </div>
                                <h6 class="mb-0 mt-3 ml-2 trn-nm" style="width: 80%" matTooltip="{{provider.name}}"> {{provider.name}}
                                </h6>
                            </div><br>
                            <div class="d-flex justify-content-start" matTooltip="Sector" style="font-size:12px">
                                <mat-icon> business</mat-icon> 
                                <span class="ml-1 mt-1 fw-500">{{provider.sector}}</span>
                            </div>
                            <div class="row" style="position:absolute;bottom:8px;font-size:12px">
                                <div class="col-sm-8 d-flex justify-content-start" style="font-size: 8px;">
                                    <a *ngIf="provider.link" href="{{provider.link}}" target="_blank"><mat-icon style="color:#fff;font-size:20px">link</mat-icon></a>
                                    <a *ngIf="provider.email" href="mailto:{{provider?.email}}" ><mat-icon style="color:#fff;font-size:20px">mail</mat-icon></a>
                                    <a *ngIf="provider.contact_no" href="tel:{{provider?.contact_no}}">	<mat-icon style="color:#fff;font-size:20px"> phone</mat-icon></a>
                                </div>
                                <div class="col-sm-4 d-flex justify-content-end float-right">
                                    <mat-icon *ngIf="provider.is_recomended">thumb_up_alt</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>