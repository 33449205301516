import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit {
  data: any;
  ColumnMode = ColumnMode;
  @Input() columns: any;
  @Input() set rows(data: any) {
    this.data = data
  };
  @Output() paginate = new EventEmitter<{}>();
  @Output() sort = new EventEmitter<{}>();
  @Output() edit = new EventEmitter<{}>();

  @Input() limit: any;
  @Input() offsets: any;
  @Input() counts: any;


  @Input() headerHeight: any;
  constructor() { }

  ngOnInit(): void {
  }

  sorts(event: any) {
    this.sort.emit(event)
  }
  edits(event: any) {
    this.edit.emit(event)
  }
  paginates(event: any) {
    this.paginate.emit(event)
  }

}
