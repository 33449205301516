import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LodashService {

  constructor() { }

  Clonedata(cloneData: any) {
    return _.cloneDeep(cloneData);
  }
  sortUnique(sortUdata: any) {
    return _.sortedUniq(sortUdata);
  }
  sortUniqueBy(sortUdata: any, sortValue: any) {
    return _.sortedUniqBy(sortUdata, [sortValue]);
  }
  orderBy(sortData: any, sortKey: any, sortOrder: any) {
    return _.orderBy(sortData, [sortKey], [sortOrder]);
  }
}
