import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScopeService {

  constructor(private http: HttpClient) { }

  createCustomScopeParameter(payload: any) {
    return this.http.post(`opportunity/create-custom-scopeparameter/`, payload);
  }

  createCategory(payload: any) {
    return this.http.post(`opportunity/create-custom-scopecategory/`, payload);
  }

  createCustomScope(payload: any) {
    return this.http.post(`opportunity/create-custom-scope/`, payload);
  }

  uploadFile(payload: any, id: any) {
    return this.http.post(`opportunity/scope-file-upload/${id}/`, payload);
  }

  getFile(id: any) {
    return this.http.get(`opportunity/scope-file-list/${id}/`);
  }

  deleteFile(payload: any, id: any) {
    return this.http.post(`opportunity/scope-file-delete/${id}/`, payload);
  }

  downloadFile(payload: any, id: any) {
    return this.http.post(`opportunity/scope-file-download/${id}/`, payload, {responseType: 'blob'});
  }
  deleteScope(uuid: any) {
    return this.http.delete(`opportunity/delete-custom-scope/${uuid}/`);
  }
  deleteScopeParameter(id: number) {
    return this.http.delete(`opportunity/delete-custom-scopeparameter/${id}/`)
  }
  deleteScopeCategory(id: number) {
    return this.http.delete(`opportunity/delete-custom-scopecategory/${id}/`)
  }
}
