<div class="row pt-4">
    <div class="col">
        <mat-card class="matcorner ">
            <div class="row" id="custom-height">
                <div class="col">
                    <div class="row">
                        <div>
                            <!-- <app-bread-crumb></app-bread-crumb> -->
                            <p class="heading-color fw-500 mb-0">{{staticText.survey.publish_survey}}</p>
                        </div>
                    </div>
                    <hr>
                    <form [formGroup]="surveyForm" (ngSubmit)="publishSurvey()" novalidate>
                        <div class="row">
                            <div class="col-md-6 pb-2">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label-design">{{staticText.survey.survey_start_date}}*</mat-label>
                                    <input matInput [matDatepicker]="startDate" (focus)="startDate.open()" readonly
                                        formControlName="survey_start_date">
                                    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                                    <mat-datepicker #startDate></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div class="col-md-6 pb-2">
                                <mat-form-field appearance="outline">
                                    <mat-label class="label-design">{{staticText.survey.survey_end_date}}*</mat-label>
                                    <input matInput [min]="surveyForm.value.survey_start_date" [matDatepicker]="endDate" (focus)="endDate.open()" readonly
                                        formControlName="survey_end_date">
                                    <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                                    <mat-datepicker #endDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <mat-label class="color-bl">{{staticText.survey.select_distributors}}* </mat-label>
                            <mat-form-field class="example-chip-list" appearance="outline">
                               
                                <mat-chip-list #chipList aria-label="Fruit selection" formControlName="respondents">
                                    <mat-chip *ngFor="let tag of tags" (removed)="removeTag(tag)">
                                        {{tag.name}}
                                        <button matChipRemove>
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <input #searchInput [formControl]="searchCtrl"
                                        [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="addTag($event,searchInput)">
                                </mat-chip-list>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addTag($event,searchInput)">
                                    <mat-option *ngFor="let tag of filterOptions | async " [value]="tag">
                                        {{tag.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div><br><br>
                        <mat-card-actions class="border-top mb-0 py-3 pb-0 foot-position  footer-align">
                            <div class="row">
                                <div class="col-md-6 text-right"></div>
                                <div class="col-md-6 text-right">
                                    <button mat-button class="next-color text-white fs-16" (click)="cancelForm()">
                                        <span class="m-0">
                                            {{staticText.buttons.cancel_btn}}
                                        </span>
                                    </button>
                                    <button mat-button class="next-color text-white fs-16  bg-green" type="submit">
                                        <span class="m-0">
                                            {{staticText.survey.publish}}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </mat-card-actions>
                    </form>
                    
                </div>
            </div>
        </mat-card>
    </div>
    <!-- <div class="col-md-4 side-nav col-12">
        <div class="row container-fluid p-0">
            <app-list [list]="[]" [type]="'timeLineHelp'"></app-list>
        </div>
    </div> -->
</div>