import { Component, Input, OnInit } from '@angular/core';
import { ChallengeWorkbenchService } from 'src/app/shared/services/challenge-workbench.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-statement-response-details',
  templateUrl: './statement-response-details.component.html',
  styleUrls: ['./statement-response-details.component.scss']
})
export class StatementResponseDetailsComponent implements OnInit {
  @Input() resDetails:any
  statements: any;
  panelOpenState: boolean = false;
  panelOpenState1: boolean = false;
  panelOpenState2: boolean = true;
  constructor(
    
    private cw: ChallengeWorkbenchService,
    private spinner : NgxSpinnerService,
    private toastrService : ToastrService,
  ) { }

  ngOnInit(): void {
  }

  openLink(x:any){
    if(x){
        window.open(x, '_blank');
    }
  }

  downloadFile(file:any){
    this.spinner.show();
    this.cw.downloadResponseAttachmentFile(file.id).subscribe((res: any) => {
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(res);
    downloadLink.setAttribute('download', file.file_name);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    this.toastrService.success('File downloaded successfully');
    this.spinner.hide();
    }, (error: any) => {
    this.spinner.hide();
    throw error;
    })
}

downloadIdeaPitchFile(file:any){
  this.spinner.show();
  this.cw.downloadIdeaPitchFile(file.id).subscribe((res: any) => {
  let downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(res);
  downloadLink.setAttribute('download', file.file_name);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  this.toastrService.success('File downloaded successfully');
  this.spinner.hide();
  }, (error: any) => {
  this.spinner.hide();
  throw error;
  })
}

}
