<div *ngIf="type == 'Tips'">
    <mat-card class="mat-size1">
        <div class="d-flex">
            <div class="flex-grow-1">
                <div class="row d-flex">
                    <div class="col-md-6">
                        <p class="text-white">Tip</p>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end">
                        <span>
                            <img src="./assets/images/Group 14960.svg" (click)="close()"
                                class="cursor-pointer" style="width:20px;">
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="mb-0 text-white fs-14" *ngIf="tipType == 'setupTip'">You can customize this
                            section by adding and removing
                            parameters as per how you want to define the opportunity.</p>

                        <p class="content mb-0 text-white fs-14" *ngIf="tipType == 'profileTip'">Fill the profile
                            information first,
                            before
                            moving to
                            fill other details.
                            Since this information is important, don't skip this step!</p>
                        <p class="content mb-0 text-white fs-14" *ngIf="tipType == 'keyTip'">Select the Key Results
                            attached to
                            various
                            Strategic
                            goals here.
                            Since this information is important, Kepler will not let you skip this step.</p>
                        <p class="content mb-0 text-white fs-14" *ngIf="tipType == 'teamTip'">Build your Opportunity
                            team here. You
                            can add
                            a new role
                            and
                            new members for the role as well.</p>
                        <p class="content mb-0 text-white fs-14" *ngIf="tipType == 'vendorTip'">Select the Vendor will
                            show the list
                            of
                            vendors. Since
                            this information is important, Kepler will not let you skip this step.</p>
                        <p class="content mb-0 text-white fs-14" *ngIf="tipType == 'timelineTip'">You can add more
                            timelines by
                            clicking on
                            "Add"
                            button. Create more timelines
                            to strengthen your opportunity workflow.</p>
                        <p class="content mb-0 text-white fs-14" *ngIf="tipType == 'scopeTip'">Click "Add Custom Scope"
                            to upload your
                            scope list manually in the right panel
                            without using system scope repository.</p>
                        <p class="content mb-0 text-white fs-14" *ngIf="tipType == 'customScopeTip'">You can add
                            category by clicking
                            on
                            "Add Category" button. Create more category to strengthen your opportunity workflow.</p>
                        <p class="content text-muted" *ngIf="tipType == 'critiTip'">It is recommended to have only 3
                            criticality
                            settings.</p>
                        <p class="content mb-0 text-white fs-14" *ngIf="tipType == 'settingTip'">Please make sure the
                            correct score
                            range is selected.</p>
                        <p class="content mb-0 text-white fs-14" *ngIf="tipType == 'moreInfoTip'">Add more sections to
                            your RFX based
                            on what you need. Select sections from the right side.</p>
                        <p class="content mb-0 text-white fs-14" *ngIf="tipType == 'termsTip'">Click "Add Custom Terms"
                            to upload your
                            terms list manually in the right panel
                            without using system terms repository</p>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</div>

<div *ngIf="type == 'Error'">
    <mat-card style="background-color: #fdf2f2!important; border:none!important">
        <div class="d-flex">
            <div class="flex-shrink-0">
                <img src="assets/images/error.png" class="tips-size">
            </div>
            <div class="flex-grow-1 ms-3">
                <div class="d-flex">
                    <p class="">UH OH!</p>
                    <mat-icon class="ms-auto cursor-pointer" (click)="close()">close</mat-icon>
                </div>
                <p class="content">You can customise this section by adding and removing parameters
                    as per how you want to define the Opportunity.</p>
            </div>
        </div>
    </mat-card>
</div>